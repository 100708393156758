import React, { useCallback, useState } from 'react';
import { adminAPI } from '../../../../api';
import AdminUserForm from './AdminUserForm';

const EditAdminUser = ({ tenant, user, onEdited, onClose }) => {
  const [errors, setErrors] = useState([]);

  const handleFormSubmit = useCallback(async (data) => {
    const response = await adminAPI.editTenantUserAdmin({
      data,
      slug: tenant.slug,
    });
    if (response.success === false) {
      setErrors(response.errors);
    } else {
      onEdited();
    }
  });

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        {user.profile.full_name}
      </h3>
      <AdminUserForm
        user={user}
        onSubmitForm={handleFormSubmit}
        onCloseForm={onClose}
        errors={errors}
      />
    </div>
  );
};

export default EditAdminUser;
