import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import NoImage from '../../../../../../assets/images/reskin-images/img--no-picture.jpeg';
import BinIcon from '../../../../../../assets/images/reskin-images/icon--bin.svg';
import SVG from 'react-inlinesvg';
import RemoveModal from './components/sidebar/DeleteModal';
import useModal from '../../../context/ModalContext';

const columns = [
  {
    label: 'Preview Image',
    field: 'thumbnail',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'Webinar Details',
    field: 'title',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '80%',
  },
];

const Webinars = ({ searchQuery, filter, setOpenSideActionBoard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowHovered, setRowHovered] = useState(false);
  const { setContent } = useModal();

  const renderRowFn = (webinar) => {
    const id = webinar?.id;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'webinars',
              action: 'view',
              ids: [id],
            })
          }
        >
          <SVG src={EyeIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'webinars',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 hover:bg-red-lighter rounded-lg p-2"
          onClick={() => {
            setContent({
              content: <RemoveModal id={id} refetch={refetch} />,
            });
          }}
        >
          <SVG src={BinIcon} />
        </button>
      </div>
    );

    return {
      id,
      thumbnail: (
        <div className="flex justify-center items-center h-full">
          <img
            src={webinar.thumbnail_url || NoImage}
            alt={`Thumbnail for ${webinar.title}`}
            className="w-16 md:w-32"
          />
        </div>
      ),
      title: (
        <div className="ml-20">
          <p className="mb-2">{webinar.title}</p>
          <a
            href={webinar.webinar_url}
            className="text-sm md:block"
            target="_blank"
            rel="noopener noreferrer"
          >
            {webinar.webinar_url || 'http://localhost:3000'}
          </a>
          {actionButtons}
        </div>
      ),
    };
  };

  const { data, refetch } = useQuery(
    ['getWebinars', { page: currentPage, search: searchQuery, filter }],
    adminAPI.getWebinars
  );

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.webinars && (
        <Table
          id="webinars"
          data={data?.webinars}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
          setRowHovered={setRowHovered}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Webinars;
