import React, { useState } from 'react';
import EditChat from '../../../../../assets/images/chat-edit.svg';
import EditChatActive from '../../../../../assets/images/chat-edit-active.svg';
import RemoveChat from '../../../../../assets/images/chat-delete.svg';
import RemoveChatActive from '../../../../../assets/images/chat-delete-active.svg';

const SavedChatItem = ({
  conversation,
  handleEditChat,
  handleRemoveChat,
  onSelectConversation,
  isCurrent,
}) => (
  <div className={`chat-entry ${isCurrent ? 'active' : ''}`}>
    <span
      title={conversation.title}
      onClick={() => onSelectConversation(conversation.id)}
    >
      {conversation.title}
    </span>
    <div className="flex items-center h-full pr-3">
      <button className="chat-entry-edit" onClick={handleEditChat}>
        <img src={EditChat} width="20px" alt="Edit" />
        <img className="active" src={EditChatActive} width="20px" alt="Edit" />
      </button>
      <button className="chat-entry-delete" onClick={handleRemoveChat}>
        <img src={RemoveChat} height="24px" alt="Delete" />
        <img
          className="active"
          src={RemoveChatActive}
          height="24px"
          alt="Delete"
        />
      </button>
    </div>
  </div>
);

export default SavedChatItem;
