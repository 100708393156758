import React, { useEffect } from 'react';
import useAuth from '../../context/AuthContext';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

const RootChat = () => {
  //check auth
  const { isLoggedIn, isLoading } = useAuth();
  const match = useMatch('/chat');
  const navigate = useNavigate();

  // if(match && !isLoggedIn) {
  //   navigate("/");
  // }

  useEffect(() => {
    // window.hsConversationsSettings = {
    //   loadImmediately: false,
    // };

    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.remove();
      },
    ];
  }, []);

  return <>{!isLoading && <Outlet />}</>;
};

export default RootChat;
