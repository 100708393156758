import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { adminAPI } from '../../../../api';
import SimpleTable from '../../../../components/SimpleTable';

import EditIcon from '../../../../../../../assets/images/reskin-images/icon--edit.svg';
import EditUser from './EditUser';
import SideActionBoard from '../../../../components/SideActionBoard';

const columns = [
  {
    label: 'Name',
    field: 'profile.full_name',
    textAlign: 'left',
    fontWeight: 'semibould',
    width: '25%',
  },
  {
    label: 'Email',
    field: 'email',
    textAlign: 'center',
    fontWeight: 'semibould',
    width: '25%',
  },
];

const UserList = ({ tenant, refetchList }) => {
  const { slug } = useParams();
  const { data: users, refetch } = useQuery(
    ['getTenantUsers', slug],
    adminAPI.getTenantUsers
  );

  const [toggleEdit, setToggleEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (refetchList) {
      refetch();
    }
  }, [refetchList]);

  const onOpenSideBar = useCallback((user) => {
    setSelectedUser(user);
    setToggleEdit(true);
  }, []);

  const onCloseSideBar = useCallback(() => {
    setSelectedUser(undefined);
    setToggleEdit(false);
  }, []);

  const actionableColumns = useMemo(() => {
    const editAction = (user) => (
      <div className={'absolute pin-r pin-t h-full items-center pr-2 flex'}>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg hover:bg-purple-200"
          onClick={() => onOpenSideBar(user)}
        >
          <SVG src={EditIcon} />
        </button>
      </div>
    );
    columns[0].onHover = editAction;
    return columns;
  }, []);

  return (
    <>
      {users && <SimpleTable data={users} columns={actionableColumns} />}
      {toggleEdit && (
        <SideActionBoard setOpenSideActionBoard={onCloseSideBar}>
          <EditUser
            tenant={tenant}
            user={selectedUser}
            onEdited={onCloseSideBar}
            onClose={onCloseSideBar}
          />
        </SideActionBoard>
      )}
    </>
  );
};

export default UserList;
