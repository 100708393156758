import React, { useState, useRef, useCallback } from 'react';
import EmailItem from './EmailItem';

export default function EmailInputList({ onChangeEmailList }) {
  const emailInputRef = useRef(null);

  const [emailList, setEmailList] = useState([]);
  const [emailInput, setEmailInput] = useState('');

  const processEmails = (input) => {
    return input.split(/[,\s]+/).filter((item) => item.match(/.+@.+\..+/));
  };

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleEmailInputKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      if (emailInput) {
        const items = processEmails(emailInput);

        setEmailList((prevEmailList) => {
          const updatedEmailList = [...prevEmailList, ...items];
          onChangeEmailList(updatedEmailList);
          return updatedEmailList;
        });

        setEmailInput('');
      }
    } else if (
      event.key === 'Backspace' &&
      emailInput === '' &&
      emailList.length > 0
    ) {
      event.preventDefault();
      setEmailList((prevEmailList) => {
        const lastEmailIndex = prevEmailList.length - 1;
        const lastEmail = prevEmailList[lastEmailIndex];
        setEmailInput(lastEmail);
        const newList = prevEmailList.slice(0, lastEmailIndex);
        onChangeEmailList(newList);
        return newList;
      });
    }
  };

  const handleEmailInputPaste = (event) => {
    event.preventDefault();
    const pastedData = (event.clipboardData || window.clipboardData).getData(
      'text'
    );

    if (pastedData) {
      const items = processEmails(pastedData);

      setEmailList((prevEmailList) => {
        const updatedEmailList = [...prevEmailList, ...items];
        onChangeEmailList(updatedEmailList);
        return updatedEmailList;
      });

      setEmailInput('');
    }
  };

  const handleEmailInputBlur = () => {
    if (emailInput) {
      const items = processEmails(emailInput);

      if (items.length) {
        setEmailList((prevEmailList) => {
          const updatedEmailList = [...prevEmailList, ...items];
          onChangeEmailList(updatedEmailList);
          return updatedEmailList;
        });
      }

      setEmailInput('');
    }
  };

  const removeEmail = useCallback((emailToRemove) => {
    setEmailList((prevEmailList) => {
      const filteredList = prevEmailList.filter(
        (email) => email !== emailToRemove
      );
      onChangeEmailList(filteredList);
      return filteredList;
    });
  }, []);

  const handleBulkEmailImport = useCallback((event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const items = target.result
        .split(/,|\r\n|"/)
        .filter((item) => item.match(/.+@.+\..+/));

      setEmailList((prevEmailList) => {
        prevEmailList.push(...items);
        onChangeEmailList(prevEmailList);
        return prevEmailList;
      });
    };

    reader.readAsText(file);
  }, []);

  return (
    <>
      <div className="flex flex-row gap-2">
        <p className="text-sm uppercase text-charcoal font-bold my-4">Emails</p>
        <input
          type="file"
          id="user-csv-upload"
          hidden
          onChange={handleBulkEmailImport}
        />
        <label
          htmlFor="user-csv-upload"
          className="font-bold 
        py-2 px-4 my-2 rounded 
        bg-grey-lightest hover:bg-purple-100 
        text-sm text-charcoal hover:text-link-purple"
        >
          From CSV
        </label>
      </div>
      <div
        style={{ gap: '8px' }}
        className="email-list w-full p-4 flex flex-wrap border-2 border-gray rounded-lg relative"
        onClick={() => emailInputRef.current.focus()}
      >
        {emailInputRef.current !== document.activeElement &&
          emailList.length === 0 && (
            <p className="text-gray absolute pin-t pin-l m-4 ml-5">
              Enter an email and press Enter or Tab
            </p>
          )}
        {emailList.map((email) => (
          <EmailItem email={email} onRemove={removeEmail} />
        ))}
        <input
          ref={emailInputRef}
          type="email"
          className="email-input outline-none bg-transparent border-none  "
          value={emailInput}
          onChange={(e) => {
            handleEmailInputChange(e);
            e.target.size = e.target.value.length || 1;
          }}
          onBlur={handleEmailInputBlur}
          onKeyDown={handleEmailInputKeyDown}
          onPaste={handleEmailInputPaste}
          size={emailInput.length || 1}
        />
      </div>
    </>
  );
}
