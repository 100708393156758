import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { adminAPI } from '../../../../api';
import SelectInput from '../../../../components/SelectInput';
import Button from '../../../../components/Button';

const ActivateGroup = ({ onGroupActivated }) => {
  const { slug } = useParams();
  const { data: groups } = useQuery(
    ['getTenantGroups', slug],
    adminAPI.getTenantGroups
  );

  const [selectedGroup, setSelectedGroup] = useState();

  const groupOptions = useMemo(
    () =>
      groups?.map((group) => ({ label: group.name, value: group.id })) ?? [],
    [groups]
  );

  const onHandleGroupSelection = useCallback(
    (e) => {
      e.preventDefault();

      const group = groups.find(({ id }) => id == e.target.group.value);
      setSelectedGroup(group);
    },
    [groups]
  );

  const onActivateGroup = useCallback(async () => {
    await adminAPI.activateTenantGroup({
      group: selectedGroup,
      slug,
    });
    onGroupActivated();
  }, [selectedGroup, slug]);

  return (
    <div className="p-8">
      {selectedGroup ? (
        <>
          <div className="pb-4" style={{ width: '400px' }}>
            <p className="pb-4">
              Are you sure you want to activate the group{' '}
              <b>{selectedGroup.name}</b>?
            </p>
            <p>
              This action will activate all users within the group and send them
              the welcome email.
            </p>
          </div>
          <Button
            type="button"
            variant="default-lowercase"
            className="font-bold capitalize"
            style={{ borderRadius: '12px' }}
            onClick={onActivateGroup}
          >
            Activate group
          </Button>
        </>
      ) : (
        <form onSubmit={onHandleGroupSelection}>
          <div className="pb-4" style={{ width: '300px' }}>
            <SelectInput name="group" options={groupOptions} />
          </div>
          <Button
            type="submit"
            variant="default-lowercase"
            className="font-bold capitalize"
            style={{ borderRadius: '12px' }}
          >
            Activate group
          </Button>
        </form>
      )}
    </div>
  );
};

export default ActivateGroup;
