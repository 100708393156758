import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import NoImage from '../../../../../../assets/images/reskin-images/img--no-picture.jpeg';
import BinIcon from '../../../../../../assets/images/reskin-images/icon--bin.svg';
import ManageIcon from '../../../../../../assets/images/reskin-images/icon--repeat.svg';
import SVG from 'react-inlinesvg';
import RemoveModal from './components/sidebar/DeleteModal';
import useModal from '../../../context/ModalContext';

const columns = [
  {
    label: 'Enabled',
    field: 'enabled',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Image',
    field: 'thumbnail',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Details',
    field: 'description',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '60%',
  },
];

const Modules = ({ searchQuery, filter, setOpenSideActionBoard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowHovered, setRowHovered] = useState(false);
  const { setContent } = useModal();

  const renderRowFn = (module) => {
    const id = module?.id;

    const actionButtons = (
      <div
        className={`pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id
            ? 'w-[5%] flex flex-col items-end justify-start'
            : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 mt-4 p-2 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'modules',
              action: 'manage',
              ids: [id],
            })
          }
        >
          <SVG src={ManageIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mt-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'modules',
              action: 'view',
              ids: [id],
            })
          }
        >
          <SVG src={EyeIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mt-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'modules',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 mt-2 mb-4 hover:bg-red-lighter rounded-lg p-2"
          onClick={() => {
            setContent({
              content: <RemoveModal id={id} refetch={refetch} />,
            });
          }}
        >
          <SVG src={BinIcon} />
        </button>
      </div>
    );

    return {
      id,
      enabled: (
        <div
          className={`flex justify-around items-center ${
            module.enabled ? 'p-2 text-green' : 'pt-2 pr-2 pl-2 text-red'
          } rounded-lg`}
        >
          {module.enabled ? '✔' : '✘'}
        </div>
      ),
      thumbnail: (
        <div className="flex justify-center items-center h-full">
          <img
            src={module.thumbnail_url || NoImage}
            alt={`Thumbnail for ${module.title}`}
            className="w-32"
          />
        </div>
      ),
      description: (
        <div className="flex ml-4 w-full min-h-220 items-center justify-between">
          <div>
            <p className="mb-2 font-semibold">{module.title}</p>
            <p className={`mb-4 ${rowHovered === id ? 'mr-8' : 'mr-24'}`}>
              {module.description}
            </p>
          </div>
          <div className="">{actionButtons}</div>
        </div>
      ),
    };
  };

  const { data, refetch } = useQuery(
    ['getModules', { page: currentPage, search: searchQuery, filter }],
    adminAPI.getModules
  );

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.courses && (
        <Table
          id="modules"
          data={data?.courses}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
          setRowHovered={setRowHovered}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Modules;
