import React, { useCallback, useState } from 'react';

import SVG from 'react-inlinesvg';

import IconPlus from '../../../../../../../assets/images/reskin-images/icon--plus.svg';
import IconMinus from '../../../../../../../assets/images/reskin-images/icon--minus.svg';
import BehaviorItem from './BehaviorItem';

const ModuleItem = ({ tenant, module }) => {
  const [toggledAccordion, setToggledAccordion] = useState(false);

  const onToggleModule = useCallback(() => {
    setToggledAccordion(!toggledAccordion);
  }, [toggledAccordion]);

  return (
    <div>
      <div
        className="border-b border-gray py-4"
        style={{ cursor: 'pointer' }}
        onClick={onToggleModule}
      >
        <div className="flex justify-between items-center">
          <div className="flex">
            <p
              className="uppercase"
              style={{
                fontSize: '14px',
                fontWeight: '700',
                color: '#6357b5',
              }}
            >
              {module.title}
            </p>
          </div>
          {toggledAccordion ? <SVG src={IconMinus} /> : <SVG src={IconPlus} />}
        </div>
      </div>

      {toggledAccordion &&
        module.behaviors?.map((behavior) => (
          <BehaviorItem
            moduleId={module.id}
            behavior={behavior}
            tenant={tenant}
          />
        ))}
    </div>
  );
};

export default ModuleItem;
