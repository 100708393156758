import React, { useState } from 'react';
import { useQuery } from 'react-query';
import SVG from 'react-inlinesvg';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { convertToDollarString } from '../../../helpers';

import { adminAPI } from '../../../api';

import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import BinIcon from '../../../../../../assets/images/reskin-images/icon--bin.svg';
import useModal from '../../../context/ModalContext';
import useAuth from '../../../context/AuthContext';
import FormLoader from '../../../components/FormLoader';
import getSimpleUsersReportData from '../../../helpers/getSimpleUsersReportData';
import getUsersH2HReportData from '../../../helpers/getUsersH2HReportData';
import generateCVS from '../../../helpers/generateCVS';
import DestroyModal from './DestroyModal';

const ActiveUsersTable = ({
  searchQuery,
  filter,
  checkedActiveUsers,
  setCheckedActiveUsers,
  setOpenSideActionBoard,
}) => {
  const [currentPage, setCurrentPage] = useState(
    data?.pagination?.current_page || 1
  );

  const { userData, isEmployee, isTenant } = useAuth();
  const isAdmin = userData?.roles?.includes('admin');
  const hasAccess = isAdmin || isEmployee;

  const { data, isLoading } = useQuery(
    [
      'activeUsers',
      {
        page: currentPage,
        search: searchQuery,
        filter,
        confirmed: true,
        count: 50,
      },
    ],
    adminAPI.getUsers
  );

  const columns = [
    {
      label: 'Name',
      field: 'name',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '25%',
    },
    {
      label: 'Email',
      field: 'email',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '30%',
    },
    {
      label: 'Type',
      field: 'application',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '20%',
    },
    {
      label: 'Signup Date',
      field: 'signupDate',
      textAlign: 'center',
      width: '20%',
    },
    { label: 'Discount', field: 'discount', textAlign: 'center', width: '15%' },
  ];

  const [isCVSLoading, setCVSIsLoading] = useState(false);

  const [rowHovered, setRowHovered] = useState(false);

  const reconstructorFn = (user) => {
    const {
      id,
      email,
      created_at: createdAt,
      profile: { first_name: firstName, last_name: lastName },
      discounted_cents: discountedCents,
      application,
    } = user;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() => {
            setOpenSideActionBoard({
              type: 'Active Users',
              action: 'view',
              ids: [id],
            });
          }}
        >
          <SVG src={EyeIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'Active Users',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
      </div>
    );

    return {
      id,
      name: (
        <div>
          {firstName} {lastName} {isTenant ? '' : actionButtons}
        </div>
      ),
      email,
      application: application ?? '---',
      signupDate: formatDate(createdAt),
      discount: convertToDollarString(discountedCents),
    };
  };

  const { setContent } = useModal();

  const editButtonLabel = checkedActiveUsers?.length >= 2 ? 'Users' : 'User';

  const handleSimpleUserReport = async () => {
    setCVSIsLoading(true);
    const coursesData = await getSimpleUsersReportData(checkedActiveUsers);

    if (coursesData) {
      coursesData.forEach((entry) => {
        const foundationsCourse = entry.courses.find(
          (course) => course.title === 'Foundations'
        );
        const otherCourses = entry.courses.filter(
          (course) => course.title !== 'Foundations'
        );
        entry.courses = [foundationsCourse, ...otherCourses];
      });

      let csv =
        'Name,' +
        coursesData?.[0]?.courses
          .map((course) => `"${course.title}"`)
          .join(',') +
        '\n';

      coursesData.forEach((entry) => {
        const name = `"${entry.first_name} ${entry.last_name}"`;
        const courseData = entry.courses
          .map((course) => course.percentage_completed)
          .join(',');
        csv += `${name},${courseData}\n`;
      });

      const currentDate = new Date().toISOString().slice(0, 10);
      generateCVS(csv, `users_report_${currentDate}`);
      setCVSIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate.replace(/\//g, '-');
  };

  const formatPhoneNumber = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, '');

    if (digits.length !== 10) {
      return 'Invalid phone number';
    }

    const formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(
      3,
      6
    )}-${digits.slice(6)}`;

    return formattedNumber;
  };

  const handleH2HReport = async () => {
    setCVSIsLoading(true);
    const habitData = await getUsersH2HReportData();

    if (habitData) {
      habitData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      let csv = 'Name, Email, Phone, Behavior, Module, Day, Created' + '\n';

      habitData.forEach((entry) => {
        csv += `"${entry.name}",${entry.email},${formatPhoneNumber(
          entry.phone
        )},"${entry.behavior_title}","${entry.module.title}",${
          entry.progress + 1
        },${formatDate(entry.created_at)}\n`;
      });

      const currentDate = new Date().toISOString().slice(0, 10);
      generateCVS(csv, `habits_report_${currentDate}`);
      setCVSIsLoading(false);
    }
  };

  return (
    <>
      {hasAccess && (
        <div>
          <div className="mb-4 ml-10 flex items-center">
            <h2 className=" font-semibold">Active Users</h2>
            {checkedActiveUsers?.length >= 1 ? (
              <>
                {!isTenant && (
                  <button
                    onClick={() =>
                      setOpenSideActionBoard({
                        type: 'Active Users',
                        action: 'edit',
                        ids: checkedActiveUsers,
                      })
                    }
                    type="button"
                    className="flex items-center font-semibold bg-purple-100 rounded-lg py-3 px-4 ml-8 hover:bg-purple-lightest"
                  >
                    <SVG src={EditIcon} className="mr-2" />
                    Edit {checkedActiveUsers.length} {editButtonLabel}
                  </button>
                )}
                <button
                  onClick={() => handleSimpleUserReport()}
                  type="button"
                  className="flex items-center font-semibold bg-purple-100 rounded-lg py-3 px-4 ml-8 hover:bg-purple-lightest"
                >
                  Activity Report for {checkedActiveUsers.length}{' '}
                  {editButtonLabel}
                </button>
              </>
            ) : null}
            {checkedActiveUsers?.length ? (
              <button
                type="button"
                className="flex items-center font-semibold hover:bg-red-lighter rounded-lg p-3 ml-4"
                onClick={() => {
                  setContent({
                    content: (
                      <DestroyModal
                        items={checkedActiveUsers}
                        type="users"
                        queryListId="activeUsers"
                        setItems={setCheckedActiveUsers}
                      />
                    ),
                  });
                }}
              >
                <SVG src={BinIcon} />
              </button>
            ) : null}
          </div>

          <button
            onClick={() => handleH2HReport()}
            type="button"
            className="flex items-center font-semibold bg-purple-100 rounded-lg py-3 px-4 ml-8 mb-8 hover:bg-purple-lightest"
          >
            Help to Habit Report
          </button>

          {!isLoading && (
            <Table
              id="active_users"
              data={data.users}
              columns={columns}
              reconstructorFn={reconstructorFn}
              checkedItems={checkedActiveUsers}
              setCheckedItems={setCheckedActiveUsers}
              setRowHovered={setRowHovered}
            />
          )}
          <Pagination
            currentPage={currentPage}
            totalPage={data?.pagination?.total_pages}
            onPageChange={(page) => setCurrentPage(page)}
          />
          {isCVSLoading && <FormLoader />}
        </div>
      )}
    </>
  );
};

export default ActiveUsersTable;
