import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Button from '../../../../components/Button';
import SideActionBoard from '../../../../components/SideActionBoard';
import InviteUser from './InviteUser';
import { adminAPI } from '../../../../api';
import SimpleTable from '../../../../components/SimpleTable';
import { formatDate } from '../../../../helpers';
import Table from '../../../../components/Table';
import useAlarm from '../../../../context/AlarmContext';
const columns = [
  {
    label: 'Email',
    field: 'email',
    textAlign: 'left',
    fontWeight: 'semibould',
    width: '25%',
  },
  {
    label: 'Sent',
    field: 'invited_at',
    textAlign: 'center',
    fontWeight: 'semibould',
    width: '25%',
  },
  {
    label: 'Expires',
    field: 'expires_at',
    textAlign: 'center',
    fontWeight: 'semibould',
    width: '25%',
  },
];

const reconstructor = (userInvite) => {
  return {
    ...userInvite,
    expires_at: formatDate(userInvite.expires_at),
    invited_at: formatDate(userInvite.invited_at),
  };
};

const UserInviteTab = ({ tenant }) => {
  const { slug } = useParams();
  const [checkedItems, setCheckedItems] = useState([]);
  const { setAlarm } = useAlarm();

  const { data: invites, refetch } = useQuery(
    ['getTenantUserInvites', slug],
    adminAPI.getTenantUserInvites
  );

  const [toggleInviteNew, setToggleInviteNew] = useState(false);

  const sendInvites = useCallback(async () => {
    const emails = checkedItems.map(({ email }) => email);
    await adminAPI.resendTenantUserInvites(slug, emails);
    setAlarm({ type: 'success', message: 'Invites Sent' });
    setCheckedItems([]);
    refetch();
  }, [checkedItems]);
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-end">
          {checkedItems.length > 0 && (
            <Button onClick={() => sendInvites()}>
              Send invite to {checkedItems.length} items
            </Button>
          )}
          <Button className="ml-4" onClick={() => setToggleInviteNew(true)}>
            Invite Users
          </Button>
        </div>
      </div>
      <div className="pt-8">
        {invites && (
          <>
            <Table
              id="tenant_invites"
              data={invites}
              columns={columns}
              reconstructorFn={reconstructor}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          </>
        )}
      </div>

      {toggleInviteNew && (
        <SideActionBoard
          setOpenSideActionBoard={() => setToggleInviteNew(false)}
        >
          <InviteUser
            tenant={tenant}
            onCreated={() => setToggleInviteNew(false)}
            onClose={() => {
              setToggleInviteNew(false);
              refetch();
            }}
          />
        </SideActionBoard>
      )}
    </>
  );
};

export default UserInviteTab;
