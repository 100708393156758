import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
// Packages
import SVG from 'react-inlinesvg';
// Context
import useAlarm from '../../context/AlarmContext';
import useModal from '../../context/ModalContext';
// Hooks
import useResponsive from '../../hooks/useResponsive';
// API
import { registerNew } from '../../api/user';
// Components
import { Main } from '../../components/layouts/Layouts';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
// Icons
import Danger from '../../../../../assets/images/reskin-images/icon--danger.svg';
// Images
import AlexBeta from './../../../../../assets/images/alex-beta.svg';
import AlexImagery from './../../../../../assets/images/alex-beta-imagery.png';
import { Link } from 'react-router-dom';
import PrivacyAlex from './PrivacyAlex';
import AgreementAlex from './AgreementAlex';

const BasicCard = ({ children, classes }) => {
  return (
    <div
      className={`bg-white px-10 py-8 ${classes}`}
      style={{
        borderRadius: '32px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 10px 50px',
      }}
    >
      {children}
    </div>
  );
};

const LeftColumn = () => {
  return (
    <>
      <div className="mb-12">
        <img src={AlexBeta} alt="ALEX Beta" />
      </div>
      <p className="mb-2 font-bold">
        Registration to use ALEX will take just a second and then you can get
        started right away.
      </p>
      <p className="mb-2">
        We think you will notice that ALEX provides coaching in a way that feels
        natural, intuitive, and personalized. The conversation will track with
        your unique needs and goals. This will not feel like cut-and-paste Q&A.
        And unlike other AI resources, ALEX will be drawing on the exclusive
        content that Admired Leadership is known for.
      </p>
      <p className="mb-2">
        One quick point here about the login step: If you are a current
        subscriber to our Admired Leadership membership platform, you will need
        to use a different password to access ALEX during this beta process.
      </p>
      <p className="mb-2 font-semibold">
        Also, part of agreeing to be in this wildly cool beta group means we
        will want to hear about your experience. So, in a few days, you will be
        asked to pick a convenient time to jump into an easy focus group
        conversation.
      </p>
      <p className="font-semibold">
        Sign up now and become one of the first to work with ALEX!
      </p>
      <div className="mt-12">
        <img src={AlexImagery} alt="ALEX Beta" />
      </div>
    </>
  );
};

const RightColumn = ({ isTablet }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company_name: '',
    terms_accepted: false,
  });
  const [errors, setErrors] = useState({ messages: [] });
  const [showErrors, setShowErrors] = useState(false);
  const queryClient = useQueryClient();
  const { setAlarm } = useAlarm();
  const { setContent } = useModal();

  const { mutate } = useMutation(registerNew, {
    onSuccess: (data) => {
      if (data.error) {
        setAlarm({ type: 'error', message: data.message });
      } else {
        setAlarm({ type: 'success', message: data.message });
        queryClient.invalidateQueries('currentUser');
        window.location.href = '/chat/confirm';
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          company_name: '',
          terms_accepted: false,
        });
      }
    },
    onError: (error) => {
      setAlarm({ type: 'error', message: error[0] });
    },
  });

  const handleChange = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const validateForm = () => {
    const newErrors = { messages: [] };
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(formData.email)) {
      newErrors.messages.push('Valid email address is required');
    }

    if (!formData.terms_accepted) {
      newErrors.messages.push(
        'You need to agree with the Privacy Policy and User Agreement'
      );
    }

    setErrors(newErrors);
    return Object.keys(newErrors?.messages).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = validateForm();

    if (!isFormValid) {
      setShowErrors(true);
    } else {
      onFinishRegistration();
    }
  };

  const onFinishRegistration = () => {
    setContent(undefined);
    const newFormData = {
      email: formData.email || '',
      profile_attributes: {
        first_name: formData.first_name || '',
        last_name: formData.last_name || '',
        phone: formData.phone || '',
        company: formData.company_name || '',
        terms_accepted: true,
      },
    };

    mutate(newFormData);
  };

  const onClickUserAgreement = () => {
    setContent({
      titleCallout: 'User Agreement',
      modalTitle: 'User Agreement',
      content: <AgreementAlex />,
    });
  };

  const onClickPrivacyPolicy = () => {
    setContent({
      titleCallout: 'Privacy Policy',
      modalTitle: 'Privacy Policy',
      content: <PrivacyAlex />,
    });
  };

  return (
    <div className="mb-8 md:mb-0" style={{ color: '#3C3C3C' }}>
      <BasicCard>
        <h6 className="mb-6 font-bold" style={{ fontSize: '20px' }}>
          Sign up today
        </h6>
        <form className="h-full user-profile-form" onSubmit={handleSubmit}>
          <div
            className="flex flex-col md:flex-row pb-6"
            style={{ gap: '24px' }}
          >
            <div style={{ width: isTablet ? '100%' : '50%', fontSize: '14px' }}>
              <FormInput
                label="First Name*"
                name="first_name"
                type="text"
                handleChange={handleChange}
                value={formData.first_name || ''}
              />
            </div>
            <div style={{ width: isTablet ? '100%' : '50%', fontSize: '14px' }}>
              <FormInput
                label="Last Name*"
                name="last_name"
                type="text"
                handleChange={handleChange}
                value={formData.last_name || ''}
              />
            </div>
          </div>

          <div className="pb-6" style={{ fontSize: '14px' }}>
            <FormInput
              label="Email*"
              name="email"
              type="text"
              handleChange={handleChange}
              value={formData.email || ''}
            />
          </div>

          <div
            className="flex flex-col md:flex-row pb-6"
            style={{ gap: '24px' }}
          >
            <div style={{ width: isTablet ? '100%' : '50%', fontSize: '14px' }}>
              <FormInput
                label="Phone"
                name="phone"
                type="text"
                handleChange={handleChange}
                value={formData.phone || ''}
              />
            </div>
            <div style={{ width: isTablet ? '100%' : '50%', fontSize: '14px' }}>
              <FormInput
                label="Company Name"
                name="company_name"
                type="text"
                handleChange={handleChange}
                value={formData.company_name || ''}
              />
            </div>
          </div>

          <div className="flex items-center">
            <input
              className="mr-4 w-5 h-5"
              type="checkbox"
              name="terms_accepted"
              checked={formData.terms_accepted}
              onChange={(e) => {
                setFormData({ ...formData, terms_accepted: e.target.checked });
              }}
            />
            <label style={{ fontSize: '14px' }}>
              By creating an account, you are agreeing to the terms of our{' '}
              <span
                onClick={() => onClickPrivacyPolicy()}
                className="text-link-purple cursor-pointer"
              >
                Privacy Policy
              </span>{' '}
              and{' '}
              <span
                onClick={() => onClickUserAgreement()}
                className="text-link-purple cursor-pointer"
              >
                User Agreement
              </span>
              .
            </label>
          </div>

          <div className="mb-6">
            {showErrors &&
              errors?.messages?.length > 0 &&
              errors?.messages?.map((message, index) => (
                <div
                  className="flex mb-2 mt-6"
                  style={{ gap: '10px' }}
                  key={index}
                >
                  <div style={{ width: '24px', height: '24px' }}>
                    <SVG src={Danger} alt="error" />
                  </div>
                  <div>{message}</div>
                </div>
              ))}

            <Button
              type="submit"
              variant="default-lowewrcase"
              className="font-bold capitalize mt-6"
              style={{ borderRadius: '16px' }}
            >
              Sign Up
            </Button>
          </div>
          {/* <Link className="mt-4" to="/users/sign_in" reloadDocument> */}
          <Link
            className="mt-4"
            to="https://sso.admiredleadership.com/access"
            reloadDocument
          >
            Or click here to log in
          </Link>
        </form>
      </BasicCard>
    </div>
  );
};

const PageContent = () => {
  const { isTablet } = useResponsive();

  const columnStyles = {
    width: isTablet ? '100%' : 'calc(50% - 36px)',
  };

  return (
    <div className="flex flex-col md:flex-row" style={{ gap: '72px' }}>
      <div style={columnStyles}>
        <LeftColumn />
      </div>
      <div style={columnStyles}>
        <RightColumn isTablet={isTablet} />
      </div>
    </div>
  );
};

const options = {
  content: <PageContent />,
  layout: 'full',
  wrapperClasses: 'flex justify-center',
  innerStyle: { width: '100%', maxWidth: '1400px' },
};

const AccesAlex = () => <Main options={options} />;

export default AccesAlex;
