import { csrfToken } from '@rails/ujs';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': csrfToken(),
};

const getDefaultHeaders = (headers) => headers;

export async function apiFetch({
  url,
  method = 'GET',
  body,
  stringify = true,
  getHeaders = getDefaultHeaders,
}) {
  const res = await fetch(url, {
    method,
    headers: getHeaders(defaultHeaders),
    body:
      body && (!stringify || typeof body === 'string')
        ? body
        : JSON.stringify(body),
  });

  return res.json();
}
