import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormInput from '../../../../components/FormInput';

const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;

const Filters = ({
  onSelectFilters,
  selectedFilters,
  showFilters = { email: true, date: true },
}) => {
  const emailFileRef = useRef();

  const [filter, setFilter] = useState(
    selectedFilters || {
      emails: '',
      date: { startDate: new Date(), endDate: '' },
    }
  );

  useEffect(() => {
    return () => {
      Object.keys(filter).forEach((key) => {
        if (!filter[key]) {
          delete filter[key];
        }
      });
      if (filter.email) {
        filter.email = filter.email.split(/[\n\r;, ]+/).join('\r');
      }
      onSelectFilters(filter);
    };
  }, [filter]);

  const handleEmailFileUpload = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const emails = csvData
          .toLowerCase()
          .split(/[\t\n\r;, ]+/)
          .filter((email) => emailRegex.test(email));

        setFilter({ ...filter, emails: emails.join('\n') });
      };
      reader.readAsText(file);
    },
    [filter]
  );

  const handleChange = useCallback(
    (key, value) => {
      setFilter({ ...filter, [key]: value });
    },
    [filter]
  );

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        Filter report
      </h3>

      {showFilters?.date && (
        <div>
          <h4 style={{ fontSize: '20px', fontWeight: '600' }}>Filter Date:</h4>
          <div className="flex mb-6 mt-4">
            <FormInput
              label="Start Date"
              name="startDate"
              value={
                filter?.date?.startDate
                  ? new Date(filter?.date?.startDate)
                      .toISOString()
                      .split('T')[0]
                  : ''
              }
              type="date"
              handleChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter?.date, startDate: e.target.value },
                })
              }
            />
            <div className="ml-4">
              <FormInput
                label="End Date"
                name="endDate"
                value={
                  filter?.date?.endDate
                    ? new Date(filter?.date?.endDate)
                        .toISOString()
                        .split('T')[0]
                    : ''
                }
                type="date"
                handleChange={(e) =>
                  setFilter({
                    ...filter,
                    date: { ...filter?.date, endDate: e.target.value },
                  })
                }
              />
            </div>
          </div>
        </div>
      )}

      {showFilters?.email && (
        <>
          <div className="flex flex-col pb-4" style={{ width: '340px' }}>
            <label className="text-sm text-charcoal font-bold pb-2">
              Email (you can separate them by comma, semicolon or line break):
            </label>
            <textarea
              name="content"
              value={filter.emails}
              onChange={(e) => handleChange('emails', e.target.value)}
              rows={8}
              className="border-2 rounded p-4 flex-grow"
            />
            <button
              type="button"
              className="p-4  font-semibold bg-purple-100 hover:bg-purple-200 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                emailFileRef.current.click();
              }}
            >
              Upload emails
            </button>
            <input
              type="file"
              accept=".csv"
              onChange={handleEmailFileUpload}
              ref={emailFileRef}
              style={{ display: 'none' }}
            />
          </div>
        </>
      )}
      <div className="flex flex-col pb-4" style={{ width: '300px' }}>
        <p
          className="underline cursor-pointer"
          onClick={() => setFilter({ emails: '' })}
        >
          clear filters
        </p>
      </div>
    </div>
  );
};

export default Filters;
