import React, { useCallback, useState } from 'react';

const SimpleTabs = ({ children, tabs = [], onTabChange }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = useCallback((index) => {
    setCurrentTab(index);
    onTabChange?.(index);
  });
  return (
    <div className="flex flex-col">
      <div className="flex justify-between border-b-2 border-gray">
        {tabs.map((tab, index) => {
          const isCurrentTab = index === currentTab;
          return (
            <button
              type="button"
              onClick={() => handleTabChange(index)}
              className={`flex flex-col items-center w-full ${
                isCurrentTab && 'font-semibold bg-gray-400'
              }`}
            >
              <div className="grow text-lg py-4">{tab.title}</div>

              {isCurrentTab && <span className="block w-full bg-purple h-1" />}
            </button>
          );
        })}
      </div>
      <div className="m-4">{children[currentTab]}</div>
    </div>
  );
};

export default SimpleTabs;
