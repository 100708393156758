import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import SideActionBoard from '../../../../components/SideActionBoard';
import UserList from './UserList';
import CreateUser from './CreateUser';
import CreateUsersBulk from './CreateUsersBulk';
import ActivateGroup from './ActivateGroup';

const UserTab = ({ tenant }) => {
  const { slug } = useParams();
  const [toggleCreateNew, setToggleCreateNew] = useState(false);
  const [toggleImportUsers, setToggleImportUsers] = useState(false);
  const [toggleActivateGroup, setToggleActivateGroup] = useState(false);
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-end">
          <Button onClick={() => setToggleCreateNew(true)}>New User</Button>
          <Button className="ml-4" onClick={() => setToggleImportUsers(true)}>
            Import Users
          </Button>
          <Button
            className="ml-4"
            disabled={tenant.draft}
            onClick={() => setToggleActivateGroup(true)}
          >
            Activate Group
          </Button>
        </div>
      </div>
      <div className="pt-8">
        <UserList
          slug={slug}
          refetchList={!(toggleCreateNew && toggleImportUsers)}
        />
      </div>
      {toggleCreateNew && (
        <SideActionBoard
          setOpenSideActionBoard={() => setToggleCreateNew(false)}
        >
          <CreateUser
            tenant={tenant}
            onCreated={() => setToggleCreateNew(false)}
            onClose={() => setToggleCreateNew(false)}
          />
        </SideActionBoard>
      )}
      {toggleImportUsers && (
        <SideActionBoard
          setOpenSideActionBoard={() => setToggleImportUsers(false)}
        >
          <CreateUsersBulk
            tenant={tenant}
            onCreated={() => setToggleImportUsers(false)}
          />
        </SideActionBoard>
      )}
      {toggleActivateGroup && (
        <SideActionBoard
          setOpenSideActionBoard={() => setToggleActivateGroup(false)}
        >
          <ActivateGroup
            onGroupActivated={() => setToggleActivateGroup(false)}
          />
        </SideActionBoard>
      )}
    </>
  );
};

export default UserTab;
