import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { useQuery } from 'react-query';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { convertToDollarString, formatDate } from '../../../helpers';

import { adminAPI } from '../../../api';

import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import BinIcon from '../../../../../../assets/images/reskin-images/icon--bin.svg';
import useModal from '../../../context/ModalContext';
import DestroyModal from './DestroyModal';
import useAuth from '../../../context/AuthContext';

const UnconfirmedUsersTable = ({
  searchQuery,
  filter,
  unconfirmedUsers,
  setUnconfirmedUsers,
  setOpenSideActionBoard,
}) => {
  const { isTenant } = useAuth();

  const [currentPage, setCurrentPage] = useState(
    data?.pagination?.current_page || 1
  );
  const { data, isLoading } = useQuery(
    [
      'unconfirmedUsers',
      { page: currentPage, search: searchQuery, filter, confirmed: false },
    ],
    adminAPI.getUsers
  );
  const columns = [
    {
      label: 'Name',
      field: 'name',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '25%',
    },
    {
      label: 'Email',
      field: 'email',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '40%',
    },
    {
      label: 'Type',
      field: 'application',
      textAlign: 'left',
      fontWeight: 'semibold',
      width: '10%',
    },
    {
      label: 'Signup Date',
      field: 'signupDate',
      textAlign: 'center',
      width: '20%',
    },
    { label: 'Discount', field: 'discount', textAlign: 'center', width: '15%' },
  ];

  const [rowHovered, setRowHovered] = useState(false);

  const reconstructorFn = (user) => {
    const {
      id,
      email,
      created_at: createdAt,
      profile: { first_name: firstName, last_name: lastName },
      discounted_cents: discountedCents,
      application,
    } = user;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'Unconfirmed Users',
              action: 'view',
              ids: [id],
            })
          }
        >
          <SVG src={EyeIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'Unconfirmed Users',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
      </div>
    );

    return {
      id,
      name: (
        <div>
          {firstName} {lastName} {isTenant ? '' : actionButtons}
        </div>
      ),
      email,
      application: application ?? '---',
      signupDate: formatDate(createdAt),
      discount: convertToDollarString(discountedCents),
    };
  };

  const { setContent } = useModal();
  return (
    <div>
      <div className="mb-4 ml-10 flex items-center">
        <h2 className="font-semibold">Unconfirmed Users</h2>
        {unconfirmedUsers?.length >= 2 ? (
          <button
            onClick={() =>
              setOpenSideActionBoard({
                type: 'Unconfirmed Users',
                action: 'edit',
                ids: unconfirmedUsers,
              })
            }
            type="button"
            className="flex items-center font-semibold bg-purple-100 rounded-lg py-3 px-4 ml-8 hover:bg-purple-lightest"
          >
            <SVG src={EditIcon} className="mr-2" />
            Edit {unconfirmedUsers.length} Selected Users
          </button>
        ) : null}
        {unconfirmedUsers?.length ? (
          <button
            type="button"
            className="flex items-center font-semibold hover:bg-red-lighter rounded-lg p-3 ml-4"
            onClick={() => {
              setContent({
                content: (
                  <DestroyModal
                    items={unconfirmedUsers}
                    type="users"
                    queryListId="unconfirmedUsers"
                    setItems={setUnconfirmedUsers}
                  />
                ),
              });
            }}
          >
            <SVG src={BinIcon} />
          </button>
        ) : null}
      </div>

      {!isLoading && (
        <Table
          id="unconfirmed_users"
          data={data.users}
          columns={columns}
          reconstructorFn={reconstructorFn}
          checkedItems={unconfirmedUsers}
          setCheckedItems={setUnconfirmedUsers}
          setRowHovered={setRowHovered}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPage={data?.pagination?.total_pages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default UnconfirmedUsersTable;
