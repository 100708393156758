import React from 'react';
import Button from '../../../../../components/Button';

const BulkResponse = ({ users, onAcknowledge }) => (
  <div className="p-8">
    <h3 className="pb-8 pt-12" style={{ fontSize: '30px', fontWeight: '800' }}>
      Users with error:
    </h3>
    <div>
      {users
        .filter((user) => user.error)
        .map(({ user, error }) => (
          <p>
            {user.email}: {error}
          </p>
        ))}
    </div>

    <h3 className="pb-8 pt-12" style={{ fontSize: '30px', fontWeight: '800' }}>
      Added users:
    </h3>
    <div>
      {users
        .filter((user) => !user.error)
        .map(({ user, error }) => (
          <p>
            {user.email}: {error}
          </p>
        ))}
    </div>

    <Button
      type="button"
      variant="default-lowercase"
      className="font-bold capitalize"
      style={{ borderRadius: '12px' }}
      onClick={onAcknowledge}
    >
      Close Sidebar
    </Button>
  </div>
);

export default BulkResponse;
