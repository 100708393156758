import React, { useState } from 'react';

const getter = (object, path) => {
  if (path.includes('.')) {
    return path.split('.').reduce((acc, key) => {
      return acc && acc[key] !== undefined ? acc[key] : undefined;
    }, object);
  }
  return object[path];
};

const Row = ({ item, columns }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <tr
      style={{ width: '96px' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {columns.map((column) => (
        <td
          className={`relative text-${column.textAlign} font-${column.fontWeight}`}
        >
          {getter(item, column.field)}
          {hovered && column.onHover?.(item)}
        </td>
      ))}
    </tr>
  );
};

const SimpleTable = ({ data, columns, rowFormatter }) => {
  return (
    <table className="react-table w-full rounded-lg overflow-hidden">
      <thead className="bg-white-faint">
        <tr>
          {columns.map((col) => (
            <th
              style={{ width: col.width }}
              className={`font-normal text-charcoal  text-${col.textAlign}`}
            >
              {col.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <Row item={rowFormatter?.(item) || item} columns={columns} />
        ))}
      </tbody>
    </table>
  );
};

export default SimpleTable;
