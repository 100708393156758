const formatBehaviorTitles = (modules) => {

  if (!modules) {
    return [];
  }

  return modules.map((module, moduleIndex) => {
    module.behaviors = module.behaviors.map((behavior, behaviorIndex) => {
      if (module.title === "Foundations") {
        if (behaviorIndex === 0) {
          behavior.behaviorNumber = "Introduction to Foundational Principles";
        } else {
          behavior.behaviorNumber = `Foundational Principle ${behaviorIndex}`;
        }
      } else
        if (behaviorIndex === 0) {
          behavior.behaviorNumber = `Introduction to ${module.title}`;
        } else {
          behavior.behaviorNumber = `${module.title}`;
        }

      return behavior;
    });
    return module;
  });
}

export default formatBehaviorTitles;