import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useModal } from '../context/ModalContext';
import MeetAlexModalImage from '../../../../assets/images/meet-alex-modal.png';
import useAuth from '../context/AuthContext';

const WelcometoAlexModal = () => {
  const { setContent } = useModal();
  const { isTenant } = useAuth();
  useEffect(() => {
    const session = JSON.parse(Cookies.get('AL_SSO_session') || '{}');
    const token = Cookies.get('AL_SSO_prev_token');
    if (!isTenant && session?.access_token != token) {
      Cookies.set('AL_SSO_prev_token', session?.access_token);

      setContent({
        titleCallout: 'New! Meet ALEX',
        modalTitle: 'New! Meet ALEX',
        content: <FirstLoginAlexModal />,
      });
    }
  }, []);

  const FirstLoginAlexModal = () => {
    return (
      <>
        <img src={MeetAlexModalImage} className="block mx-auto" />
        <div className="p-4">
          <h2 className="mb-4 text-center">
            Practice any behavior with our AI Coach ALEX
          </h2>
          <p className="mb-4 text-center">
            ALEX is privy to everything Admired Leadership, including all the
            behaviors and Field Notes. So use ALEX as a guide
            and practice partner. You'll find ALEX is ready to engage in a full
            coaching conversation on any topic but will answer and engage only
            with Admired Leadership content when you ask questions about your
            leadership situations and interests.
          </p>
          <p className="mb-4 text-center">
            ALEX is ready to roleplay, rehearse situations, and explore
            questions or challenges about the behaviors and routines in each
            module. As such, ALEX is a tremendous resource for learning how to
            apply the behaviors and ideas of Admired Leadership to your own
            situations and organization. 
          </p>
          <p className="mb-4 text-center">
            Just click the link below to meet ALEX and begin a conversation with
            an incredible learning tool. 
          </p>
        </div>
        <div className="flex justify-center">
          <a
            className="uppercase no-underline flex px-6 py-3 border-2 text-white font-extrabold uppercase rounded-full bg-link-purple border-link-purple hover:bg-purple-500 hover:border-purple-500"
            style={{ width: 'fit-content' }}
            href="https://alex.admiredleadership.com"
            target="_blank"
          >
            Chat with ALEX
          </a>
        </div>
      </>
    );
  };

  return <></>;
};

export default WelcometoAlexModal;
