import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { formatDate } from '../../../helpers';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import SVG from 'react-inlinesvg';

const columns = [
  {
    label: 'Date',
    field: 'date',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'Item',
    field: 'item',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Buyer',
    field: 'buyer',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Total',
    field: 'total',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '15%',
  },
];

const Renewals = ({ searchQuery, filter, setOpenSideActionBoard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowHovered, setRowHovered] = useState(false);

  const renderRowFn = (order) => {
    const id = order?.id;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'renewals',
              action: 'view',
              ids: [id],
            })
          }
        >
          <SVG src={EyeIcon} />
        </button>
      </div>
    );

    return {
      id,
      date: (
        <div>
          {formatDate(order?.date)}
          {actionButtons}
        </div>
      ),
      item: order?.item,
      buyer: order?.buyer,
      total: `$${order?.total}`,
    };
  };

  const { data, refetch } = useQuery(
    [
      'getOrders',
      { page: currentPage, search: searchQuery, filter, type: 'renewals' },
    ],
    adminAPI.getOrders
  );

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.orders && (
        <Table
          data={data?.orders}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
          setRowHovered={setRowHovered}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Renewals;
