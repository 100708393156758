import React from 'react';

import useAuth from '../../context/AuthContext';

const Sender = ({ children }) => {
  const { userData } = useAuth();
  const blankPicture = userData?.profile?.avatar?.url?.includes('blank');

  return (
    <div className="chat-row sender">
      <div className="sender-picture">
        {blankPicture ? (
          userData?.profile?.first_name?.charAt(0)
        ) : (
          <img alt="Sender" src={userData?.profile?.avatar?.url} />
        )}
      </div>
      <div className="sender-message">{children}</div>
    </div>
  );
};

export default Sender;
