import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { adminAPI } from '../../../api';
import SimpleTabs from '../../../components/SimpleTabSelection';
import AdminTab from './admin/AdminTab';
import BehaviorTab from './behavior/BehaviorTab';
import EditTenantForm from './EditTenantForm';
import UserTab from './user/UserTab';
import UserInviteTab from './userInvites/UserInviteTab';

const tabs = [
  {
    title: 'Basic Info',
  },
  {
    title: 'Admins',
  },
  {
    title: 'Users',
  },
  {
    title: 'Invites',
  },
  {
    title: 'Behaviors',
  },
];

const EditTenant = () => {
  const { slug } = useParams();
  const { data: tenant, refetch } = useQuery(slug, adminAPI.getTenantBySlug);

  return (
    <div>
      <div className="flex align-center pb-4">
        <h1>{tenant?.name}</h1>
        <p
          className={`pl-2 self-end font-bold ${
            tenant?.draft ? 'text-rose-600' : 'text-green-600'
          }`}
        >
          {tenant?.draft ? 'draft' : 'active'}
        </p>
      </div>
      <SimpleTabs tabs={tabs} onTabChange={() => refetch()}>
        <EditTenantForm tenant={tenant} onEdited={refetch} />
        <AdminTab tenant={tenant} />
        <UserTab tenant={tenant} />
        <UserInviteTab tenant={tenant} />
        <BehaviorTab tenant={tenant} />
      </SimpleTabs>
    </div>
  );
};

export default EditTenant;
