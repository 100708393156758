import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { adminAPI } from '../../../../../api';
import FormLoader from '../../../../../components/FormLoader';
import useAlarm from '../../../../../context/AlarmContext';
import ChevronRightIcon from '../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';
import SVG from 'react-inlinesvg';

const CourseBehaviors = ({ courseId }) => {
  const { setAlarm } = useAlarm();

  const [availableBehaviors, setAvailableBehaviors] = useState([]);
  const [selectedBehaviors, setSelectedBehaviors] = useState([]);

  const { data: allBehaviors, isLoading: loadingAll } = useQuery(
    ['getBehaviors'],
    adminAPI.getBehaviors,
    {
      onSuccess: (data) => {
        setAvailableBehaviors(
          data.sort((a, b) => a.title.localeCompare(b.title))
        );
      },
    }
  );

  const {
    data: courseBehaviors,
    isLoading: loadingCourse,
    refetch,
  } = useQuery(
    ['getCourseBehaviors', { courseId }],
    () => adminAPI.getCourseBehaviors(courseId),
    {
      onSuccess: (data) => {
        console.log('peguei os dados do servidor');

        const selectedIds = data.map((b) => b.id);
        setSelectedBehaviors(
          data.sort((a, b) => a.title.localeCompare(b.title)).map((b) => b.id)
        );
        setAvailableBehaviors((prevBehaviors) =>
          prevBehaviors.filter((b) => !selectedIds.includes(b.id))
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: updateCourseBehaviors, isLoading: updating } = useMutation(
    (behaviorIds) => adminAPI.updateCourseBehaviors(courseId, behaviorIds),
    {
      onSuccess: () => {
        setAlarm({
          type: 'success',
          message: 'Behaviors updated successfully',
        });
        refetch();
      },
      onError: (error) => {
        setAlarm({ type: 'error', message: error.message });
      },
    }
  );

  const handleBehaviorToggle = (behaviorId) => {
    if (selectedBehaviors.includes(behaviorId)) {
      const behaviorToAdd = allBehaviors.find((b) => b.id === behaviorId);
      setSelectedBehaviors((prevSelected) =>
        prevSelected.filter((id) => id !== behaviorId)
      );
      setAvailableBehaviors((prevAvailable) =>
        [...prevAvailable, behaviorToAdd].sort((a, b) =>
          a.title.localeCompare(b.title)
        )
      );
    } else {
      const behaviorToRemove = allBehaviors.find((b) => b.id === behaviorId);
      setSelectedBehaviors((prevSelected) =>
        [...new Set([...prevSelected, behaviorId])].sort((a, b) =>
          allBehaviors
            .find((x) => x.id === a)
            .title.localeCompare(allBehaviors.find((x) => x.id === b).title)
        )
      );
      setAvailableBehaviors((prevAvailable) =>
        prevAvailable.filter((b) => b.id !== behaviorId)
      );
    }
  };

  console.log({ selectedBehaviors });

  const handleSubmit = () => {
    updateCourseBehaviors(selectedBehaviors); // Somente atualiza no servidor quando o usuário clicar
  };

  if (loadingAll || loadingCourse) return <FormLoader />;

  return (
    <div>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>
      <div className="overflow-y-scroll h-full px-8 py-8">
        <h1>Manage Course Behaviors</h1>
        <div style={{ gap: '16px' }} className="flex flex-col my-12">
          <h2>Behaviors in Course</h2>
          <ul className="border p-4 h-64 overflow-y-auto">
            {selectedBehaviors
              .map((behaviorId) =>
                allBehaviors.find((b) => b.id === behaviorId)
              )
              .map((behavior) => (
                <li
                  key={behavior.id}
                  className="flex items-center justify-between mb-2"
                >
                  <span>{behavior.title}</span>
                  <button
                    className="p-2 bg-red text-white rounded"
                    onClick={() => handleBehaviorToggle(behavior.id)}
                  >
                    Remove
                  </button>
                </li>
              ))}
          </ul>
        </div>
        <div>
          <h2>All Behaviors</h2>
          <ul className="border p-4 h-64 overflow-y-auto">
            {availableBehaviors.map((behavior) => (
              <li
                key={behavior.id}
                className="flex items-center justify-between mb-2"
              >
                <span>{behavior.title}</span>
                <button
                  className="p-2 bg-purple-500 text-white rounded"
                  onClick={() => handleBehaviorToggle(behavior.id)}
                >
                  Add
                </button>
              </li>
            ))}
          </ul>
        </div>

        <button
          className="mt-6 p-3 bg-purple text-white rounded"
          onClick={handleSubmit}
          disabled={updating}
        >
          {updating ? 'Updating...' : 'Save Changes'}
        </button>
      </div>
    </div>
  );
};

export default CourseBehaviors;
