import React from 'react';

const Prompts = ({ list, onPromptSelect }) => {
  return (
    <div className="chat-prompts">
      {list.map((item, index) => (
        <button key={index} onClick={() => onPromptSelect(item)}>
          <span>{item}</span>
        </button>
      ))}
    </div>
  );
};

export default Prompts;
