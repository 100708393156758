import React from 'react';
import SideActionBoard from '../../../../../../components/SideActionBoard';

import Example from './Example';

const Sidebar = ({ openSideActionBoard, setOpenSideActionBoard }) => {
  const { action, ids } = openSideActionBoard;

  return (
    <SideActionBoard setOpenSideActionBoard={setOpenSideActionBoard}>
      <Example
        id={ids[0]}
        type={action}
        openSideActionBoard={openSideActionBoard}
        setOpenSideActionBoard={setOpenSideActionBoard}
      />
      )
    </SideActionBoard>
  );
};

export default Sidebar;
