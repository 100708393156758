import React, { useState } from 'react';
import SideActionBoard from '../../../../../../components/SideActionBoard';

import Map from './Map';

const Sidebar = ({ openSideActionBoard, setOpenSideActionBoard }) => {
  const { action, ids } = openSideActionBoard;

  return (
    <SideActionBoard setOpenSideActionBoard={setOpenSideActionBoard}>
      <Map
        id={ids[0]}
        type={action}
        openSideActionBoard={openSideActionBoard}
        setOpenSideActionBoard={setOpenSideActionBoard}
      />
      )
    </SideActionBoard>
  );
};

export default Sidebar;
