import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import NoImage from '../../../../../../assets/images/reskin-images/img--no-picture.jpeg';
import BinIcon from '../../../../../../assets/images/reskin-images/icon--bin.svg';
import SVG from 'react-inlinesvg';
import RemoveModal from './components/sidebar/DeleteModal';
import useModal from '../../../context/ModalContext';

const columns = [
  {
    label: 'Enabled',
    field: 'enabled',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Image',
    field: 'thumbnail',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Details',
    field: 'title',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '40%',
  },
  {
    label: 'Price',
    field: 'price',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '20%',
  },
];

const Behaviors = ({ searchQuery, filter, setOpenSideActionBoard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowHovered, setRowHovered] = useState(false);
  const { setContent } = useModal();

  const renderRowFn = (behavior) => {
    const id = behavior?.id;

    const actionButtons = (
      <div
        className={`pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'w-[5%] flex items-end justify-start' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 mr-2  p-2 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'behaviors',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 hover:bg-red-lighter rounded-lg p-2"
          onClick={() => {
            setContent({
              content: <RemoveModal id={id} refetch={refetch} />,
            });
          }}
        >
          <SVG src={BinIcon} />
        </button>
      </div>
    );

    return {
      id,
      enabled: (
        <div
          className={`flex justify-around items-center ${
            behavior.enabled ? 'p-2 text-green' : 'pt-2 pr-2 pl-2 text-red'
          } rounded-lg`}
        >
          {behavior.enabled ? '✔' : '✘'}
        </div>
      ),
      thumbnail: (
        <div className="flex justify-center items-center h-full">
          <img
            src={behavior.thumbnail_url || NoImage}
            alt={`Thumbnail for ${behavior.title}`}
            className="w-32"
          />
        </div>
      ),
      title: (
        <div className="flex ml-4 w-full min-h-220 items-center justify-between">
          <div>
            <p className="mb-2 font-semibold">{behavior.title}</p>
          </div>
        </div>
      ),
      price: (
        <div className="flex ml-4 w-full min-h-220 items-center justify-between">
          <div>
            <p className="mb-2 font-semibold">$ {behavior.price?.toFixed(2)}</p>
          </div>
          <div className="">{actionButtons}</div>
        </div>
      ),
    };
  };

  const { data, refetch } = useQuery(
    ['getAllBehaviors', { page: currentPage, search: searchQuery, filter }],
    adminAPI.getAllBehaviors
  );

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.behaviors && (
        <Table
          id="behaviors"
          data={data?.behaviors}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
          setRowHovered={setRowHovered}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Behaviors;
