import React, { useCallback, useState } from 'react';
import { adminAPI } from '../../../../api';
import UserBulkForm from './bulk/UserBulkForm';
import BulkResponse from './bulk/BulkResponse';

const CreateUsersBulk = ({ tenant, onCreated }) => {
  const [bulkResponse, setBulkResponse] = useState();
  const handleFormSubmit = useCallback(
    async (data) => {
      const response = await adminAPI.createTenantUserBulk({
        data,
        slug: tenant.slug,
      });
      setBulkResponse(response);
    },
    [tenant]
  );

  return (
    <>
      {bulkResponse ? (
        <BulkResponse users={bulkResponse} onAcknowledge={onCreated} />
      ) : (
        <UserBulkForm handleSubmit={handleFormSubmit} />
      )}
    </>
  );
};

export default CreateUsersBulk;
