import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { userAPI } from '../api';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const { data, isLoading } = useQuery('currentUser', userAPI.getUser);

  return (
    <AuthContext.Provider
      value={{
        userData: data?.user,
        isLoading,
        isLoggedIn: !!data?.user,
        isCarneyTeam: data?.user?.is_carney_team,
        isEmployee: data?.user?.is_employee,
        accessType: data?.user?.profile?.hubspot?.access_type,
        isTenant: Boolean(data?.tenant && data?.tenant?.slug !== 'public'),
        tenant: data?.tenant,
        isAdmin: data?.user?.roles?.includes('admin'),
        expires_at: data?.user?.expires_at,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Create a custom hook to use the AuthContext, call it useAuth
function useAuth() {
  return useContext(AuthContext);
}

export default useAuth;
