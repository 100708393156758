import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { adminAPI } from '../../../../../api';
import SVG from 'react-inlinesvg';
import ChevronRightIcon from '../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';
import useAlarm from '../../../../../context/AlarmContext';

const EditGift = ({ id, openSideActionBoard, setOpenSideActionBoard }) => {
  const { data: gift, refetch } = useQuery(
    ['getOrderGiftDetails', { id }],
    adminAPI.getOrderGiftDetails
  );

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [message, setMessage] = useState('');
  const [anonymous, setAnonymous] = useState(false);
  const { setAlarm } = useAlarm();

  useEffect(() => {
    if (gift) {
      setRecipientName(gift?.recipient_name);
      setRecipientEmail(gift?.recipient_email);
      setMessage(gift?.message);
      setAnonymous(gift?.anonymous);
    }
  }, [gift]);

  const { mutate: updateOrderGift } = useMutation(
    (updatedGift) => adminAPI.getUpdateOrder({ id, body: updatedGift }),
    {
      onSuccess: () => {
        refetch();
        setAlarm({ type: 'success', message: 'Gift updated successfully' });
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    updateOrderGift({
      recipient_name: recipientName,
      recipient_email: recipientEmail,
      message,
      anonymous,
    });
  };

  return (
    <>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>
      <form
        onSubmit={handleSubmit}
        className="max-w-xl mx-auto p-4 bg-white rounded-lg"
      >
        <h1 className="text-3xl font-bold mb-4">Edit Gift</h1>

        <div className="mb-4">
          <label htmlFor="recipientName" className="block font-semibold mb-1">
            Recipient name
          </label>
          <input
            id="recipientName"
            type="text"
            value={recipientName}
            onChange={(e) => setRecipientName(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="recipientEmail" className="block font-semibold mb-1">
            Recipient email
          </label>
          <input
            id="recipientEmail"
            type="email"
            value={recipientEmail}
            onChange={(e) => setRecipientEmail(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block font-semibold mb-1">
            Message (optional)
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border rounded"
            rows={4}
          />
        </div>

        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={anonymous}
              onChange={() => setAnonymous(!anonymous)}
              className="form-checkbox"
            />
            <span className="ml-2">Send gift anonymously?</span>
          </label>
        </div>

        <div className="flex justify-between">
          <button
            type="submit"
            className="px-4 py-2 bg-purple text-white rounded hover:bg-purple-dark"
          >
            Save and Resend Email
          </button>
        </div>
      </form>
    </>
  );
};

export default EditGift;
