import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { formatDate } from '../../../helpers';
import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';
import EyeIcon from '../../../../../../assets/images/reskin-images/icon--eye.svg';
import SVG from 'react-inlinesvg';

const columns = [
  {
    label: 'Date',
    field: 'date',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Buyer',
    field: 'buyer',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '25%',
  },
  {
    label: 'Sent to',
    field: 'sent_to',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '25%',
  },
  {
    label: 'Email sent to',
    field: 'email_sent_to',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '25%',
  },
  {
    label: 'Status',
    field: 'status',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '15%',
  },
];

const Gifts = ({ searchQuery, filter, setOpenSideActionBoard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowHovered, setRowHovered] = useState(false);

  const renderRowFn = (data) => {
    const id = data?.id;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg mr-2 hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'gifts',
              action: 'view',
              ids: [id],
            })
          }
        >
          <SVG src={EyeIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'gifts',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
      </div>
    );

    return {
      id,
      date: (
        <div>
          {formatDate(data?.date)}
          {actionButtons}
        </div>
      ),
      buyer: data?.buyer,
      sent_to: data?.sent_to || 'N/A',
      email_sent_to: data?.email_sent_to || 'N/A',
      status: data?.status || 'N/A',
    };
  };

  const { data, refetch } = useQuery(
    [
      'getOrders',
      { page: currentPage, search: searchQuery, filter, type: 'gifts' },
    ],
    adminAPI.getOrders
  );

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.orders && (
        <Table
          data={data?.orders}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
          setRowHovered={setRowHovered}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Gifts;
