import React, { useState } from 'react';
import SideActionBoard from '../../../../../components/SideActionBoard';

import Module from './Module';
import ManageCourseBehaviors from './ManageCourseBehaviors';

const Sidebar = ({ openSideActionBoard, setOpenSideActionBoard }) => {
  const { action, ids } = openSideActionBoard;

  return (
    <SideActionBoard setOpenSideActionBoard={setOpenSideActionBoard}>
      {action === 'manage' ? (
        <ManageCourseBehaviors
          courseId={ids[0]}
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      ) : (
        <Module
          id={ids[0]}
          type={action}
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      )}
    </SideActionBoard>
  );
};

export default Sidebar;
