import React, { useCallback, useState } from 'react';

export default function ApplicationSelect({ onChange }) {
  const [application, setApplication] = useState('ALD+ALEX');

  const handleApplicationChange = useCallback((newApplication) => {
    setApplication(newApplication);
    onChange?.(newApplication);
  }, []);

  return (
    <div>
      <p className="text-sm uppercase text-charcoal font-bold mb-4">
        Applications
      </p>

      <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
        <button
          onClick={() => {
            handleApplicationChange('ALD+ALEX');
          }}
          type="button"
          className={`flex flex-col items-center justify-center py-4 w-1/2 ${
            application === 'ALD+ALEX' && 'bg-purple-200'
          }`}
        >
          <p className="text-sm  font-semibold">ALD+ALEX</p>
        </button>
        <button
          onClick={() => {
            handleApplicationChange('ALD');
          }}
          type="button"
          className={`flex flex-col items-center justify-center py-4 w-1/2 ${
            application === 'ALD' && 'bg-purple-200'
          }`}
        >
          <p className="text-sm  font-semibold">Just ALD</p>
        </button>
        <button
          onClick={() => {
            handleApplicationChange('ALEX');
          }}
          type="button"
          className={`flex flex-col items-center justify-center py-4 w-1/2 ${
            application === 'ALEX' && 'bg-purple-200'
          }`}
        >
          <p className="text-sm  font-semibold">Just ALEX</p>
        </button>
      </div>
    </div>
  );
}
