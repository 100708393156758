import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import SVG from 'react-inlinesvg';
import NoImage from '../../../../../../../../assets/images/reskin-images/img--no-picture.jpeg';
import ChevronRightIcon from '../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';
import { adminAPI } from '../../../../../api';
import FormLoader from '../../../../../components/FormLoader';
import useAlarm from '../../../../../context/AlarmContext';

const ModuleForm = ({ id, type, setOpenSideActionBoard }) => {
  const { setAlarm } = useAlarm();
  const [formData, setFormData] = useState({
    title: '',
    sku: '',
    options: {
      display: '',
      icon: '',
    },
    description: '',
    thumbnail_url: null,
    enabled: false,
  });

  const { data: module, refetch } = useQuery(
    ['getModule', { id }],
    adminAPI.getModule,
    {
      enabled: type === 'edit' || type === 'view',
    }
  );

  useEffect(() => {
    if (type === 'edit' || type === 'view') {
      refetch();
    }
  }, [id, refetch, type]);

  useEffect(() => {
    if (module) {
      const course = module?.course;

      setFormData({
        title: course.title || '',
        sku: course.sku || '',
        options: {
          display: course.options?.display || '',
          icon: course.options?.icon || '',
        },
        description: course.description || '',
        thumbnail_url: course.thumbnail_url || null,
        enabled: course.enabled || false,
      });
    }
  }, [module]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeOptions = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      options: {
        ...prevData.options,
        [name]: value,
      },
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      thumbnail_url: e.target.files[0],
    }));
  };

  const { mutate: createModuleMutation, isLoading: createLoading } =
    useMutation(adminAPI.createModule, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Module created successfully' });
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const { mutate: updateModuleMutation, isLoading: updateLoading } =
    useMutation(adminAPI.updateModule, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Module updated successfully' });
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === 'create') {
      createModuleMutation(formData);
    } else if (type === 'edit') {
      updateModuleMutation({ id, moduleData: formData });
    }
  };

  const isDetailView = type === 'view';

  const label = {
    view: 'Module Details',
    edit: 'Edit Module',
    create: 'Create Module',
  };

  return (
    <>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>

      <form
        className="overflow-y-scroll h-full px-8 py-8"
        onSubmit={handleSubmit}
      >
        <h1>{label[type]}</h1>

        <div style={{ gap: '16px' }} className="flex flex-col my-12">
          <div className="mb-6">
            {isDetailView ? (
              <img
                src={formData.thumbnail_url ? formData.thumbnail_url : NoImage}
                alt="Module Poster"
                className="w-64 h-auto mx-auto"
              />
            ) : (
              <>
                <label className="font-semibold text-gray-700 mb-2">
                  Poster Image
                </label>
                <input
                  type="file"
                  name="poster"
                  onChange={handleFileChange}
                  className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  disabled={isDetailView}
                />
              </>
            )}
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              rows="4"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">SKU</label>
            <input
              type="text"
              name="sku"
              value={formData.sku}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="flex gap-4 mb-6">
            <div className="w-1/2">
              <label className="font-semibold text-gray-700">Display</label>
              <select
                name="display"
                value={formData.options?.display}
                onChange={handleChangeOptions}
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={isDetailView}
              >
                <option value="default">Default</option>
                <option value="featured">Featured</option>
                <option value="expandable">Expandable</option>
              </select>
            </div>

            <div className="w-1/2">
              <label className="font-semibold text-gray-700">Icon</label>
              <select
                name="icon"
                value={formData.options?.icon}
                onChange={handleChangeOptions}
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={isDetailView}
              >
                <option value="star">Star</option>
                <option value="logo">ALD logo</option>
                <option value="number">Number</option>
                <option value="none">None</option>
              </select>
            </div>
          </div>

          <div className="flex items-center mb-6">
            <input
              type="checkbox"
              name="enabled"
              checked={formData.enabled}
              onChange={(e) =>
                setFormData({ ...formData, enabled: e.target.checked })
              }
              disabled={isDetailView}
              className="mr-2"
            />
            <label className="font-semibold text-gray-700">Enabled</label>
          </div>
        </div>

        {!isDetailView && (
          <button
            className="mb-20 p-4 bg-purple-500 text-white font-semibold rounded-lg hover:bg-purple-700 transition-colors"
            type="submit"
          >
            {type === 'create' ? 'Create Module' : 'Update Module'}
          </button>
        )}

        {(createLoading || updateLoading) && <FormLoader />}
      </form>
    </>
  );
};

export default ModuleForm;
