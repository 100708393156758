import React, { useState } from 'react';
import Button from '../../../../components/Button';
import SideActionBoard from '../../../../components/SideActionBoard';
import CreateAdminUser from './CreateAdminUser';
import AdminUserList from './AdminUserList';

const AdminTab = ({ tenant }) => {
  const [toggleCreateNew, setToggleCreateNew] = useState(false);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-end">
          <Button onClick={() => setToggleCreateNew(true)}>New Admin</Button>
        </div>
        <div className="pt-8">
          <AdminUserList slug={tenant.slug} refetchList={!toggleCreateNew} />
        </div>
      </div>
      {toggleCreateNew && (
        <SideActionBoard
          setOpenSideActionBoard={() => setToggleCreateNew(false)}
        >
          <CreateAdminUser
            tenant={tenant}
            onCreated={() => setToggleCreateNew(false)}
            onClose={() => setToggleCreateNew(false)}
          />
        </SideActionBoard>
      )}
    </>
  );
};

export default AdminTab;
