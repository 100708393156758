import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import SelectInput from '../../../components/SelectInput';
import { adminAPI } from '../../../api';

const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;

const Filters = ({ onSelectFilters, selectedFilters }) => {
  const emailFileRef = useRef();

  const [filter, setFilter] = useState(
    selectedFilters || { emails: '', company: '' }
  );

  const { data: userCompanies } = useQuery(
    ['getUsersCompanies'],
    adminAPI.getUsersCompanies
  );

  useEffect(() => {
    // onSelectFilters();
    return () => {
      Object.keys(filter).forEach((key) => {
        if (!filter[key]) {
          delete filter[key];
        }
      });
      if (filter.email) {
        filter.email = filter.email.split(/[\n\r;, ]+/).join('\r');
      }
      onSelectFilters(filter);
    };
  }, [filter]);

  const companies = useMemo(
    () => [
      { label: 'Select a company', value: '' },
      ...(userCompanies?.map((company) => ({
        label: company,
        value: company,
      })) || []),
    ],
    [userCompanies]
  );

  const handleEmailFileUpload = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const emails = csvData
          .toLowerCase()
          .split(/[\t\n\r;, ]+/)
          .filter((email) => emailRegex.test(email));

        console.log({ emails });

        setFilter({ ...filter, emails: emails.join('\n') });
      };
      reader.readAsText(file);
    },
    [filter]
  );

  const handleChange = useCallback(
    (key, value) => {
      setFilter({ ...filter, [key]: value });
    },
    [filter]
  );

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        Filter users
      </h3>
      <div className="flex flex-col pb-4" style={{ width: '300px' }}>
        <label className="text-sm text-charcoal font-bold pb-2">Company:</label>
        <SelectInput
          name="company"
          value={filter.company}
          onChange={(_name, value) => handleChange('company', value)}
          options={companies || []}
        />
      </div>

      <div className="flex flex-col pb-4" style={{ width: '300px' }}>
        <label className="text-sm text-charcoal font-bold pb-2">
          Email (you can separate them by comma, semicolon or line break):
        </label>
        <textarea
          name="content"
          value={filter.emails}
          onChange={(e) => handleChange('emails', e.target.value)}
          rows={8}
          className="border-2 rounded p-4 flex-grow"
        />
        <button
          type="button"
          className="p-4  font-semibold bg-purple-100 hover:bg-purple-200 rounded-lg"
          onClick={(e) => {
            e.preventDefault();
            emailFileRef.current.click();
          }}
        >
          Upload emails
        </button>
        <input
          type="file"
          accept=".csv"
          onChange={handleEmailFileUpload}
          ref={emailFileRef}
          style={{ display: 'none' }}
        />
      </div>
      <div className="flex flex-col pb-4" style={{ width: '300px' }}>
        <p
          className="underline cursor-pointer"
          onClick={() => setFilter({ emails: '', company: '' })}
        >
          clear filters
        </p>
      </div>
    </div>
  );
};

export default Filters;
