import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { formatDate } from '../../../helpers';

const columns = [
  {
    label: 'Name',
    field: 'full_name',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'E-mail',
    field: 'email',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'Conversations',
    field: 'conversations',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Access Type',
    field: 'user_access_type',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Expiration Date',
    field: 'expiration_date',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Activation Date',
    field: 'created_at',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Invitation Sent On',
    field: 'invitation_sent_on',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '12%',
  },
];

const renderRowFn = (user) => {
  return {
    full_name: user?.full_name,
    email: user?.email,
    conversations: user?.conversations,
    created_at: formatDate(user?.created_at),
    expiration_date: formatDate(user?.expiration_date),
    user_access_type: user?.user_access_type ?? '---',
    invitation_sent_on: user?.invitation_sent_on
      ? formatDate(user?.invitation_sent_on)
      : '---',
  };
};

const AlexUsage = ({ searchQuery, filter }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch } = useQuery(
    ['getReportAlexUsage', { page: currentPage, search: searchQuery, filter }],
    adminAPI.getReportAlexUsage
  );

  useEffect(() => {
    if (
      data?.pagination?.current_page &&
      currentPage !== data?.pagination?.current_page
    ) {
      setCurrentPage(data.pagination.current_page);
    }
  }, [data?.pagination?.current_page, currentPage]);

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.reports && (
        <Table
          data={data?.reports}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default AlexUsage;
