import React from 'react';
import { useMutation } from 'react-query';

import { adminAPI } from '../../../../../../api';
import useModal from '../../../../../../context/ModalContext';
import useAlarm from '../../../../../../context/AlarmContext';
import FormLoader from '../../../../../../components/FormLoader';

const RemoveModal = ({ id, behaviorId, refetch }) => {
  const { setAlarm } = useAlarm();
  const { closeModal } = useModal();

  const { mutate: deleteExerciseMutation, isLoading } = useMutation(
    adminAPI.deleteExercise,
    {
      onSuccess: () => {
        refetch();
        setAlarm({ type: 'success', message: 'Exercise delete successfully' });
        closeModal();
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
        closeModal();
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteExerciseMutation({ behaviorId, id });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <strong>Are you sure you want to delete this exercise?</strong>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="font-semibold p-4 rounded-lg bg-gray text-black mr-2"
            >
              No
            </button>
            <button
              type="submit"
              className="font-semibold p-4 rounded-lg bg-red text-white"
            >
              Yes
            </button>
          </div>
        </div>
      </form>
      {isLoading && <FormLoader />}{' '}
    </>
  );
};

export default RemoveModal;
