import React, { useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import useStopScrolling from '../../../hooks/useStopScrolling';
import AdminUserSideBar from '../../../components/AdminUserSideBar';

import PendingInvitesTable from './PendingInvitesTable';
import UnconfirmedUsersTable from './UnconfirmedUsersTable';
import ActiveUsersTable from './ActiveUsersTable';
import TabSelection from './TabSelection';

const AdminUser = () => {
  const [currentTab, setCurrentTab] = useState('Active Users');
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedActiveUsers, setCheckedActiveUsers] = useState([]);
  const [unconfirmedUsers, setUnconfirmedUsers] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [openSideActionBoard, setOpenSideActionBoard] = useState(); // {type: "", action: "", ids: # }
  const [filter, setFilter] = useState({});

  const debouncedSearch = useDebounce(searchQuery, 300);

  useStopScrolling(openSideActionBoard);

  return (
    <div>
      {openSideActionBoard && (
        <AdminUserSideBar
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
          setCheckedActiveUsers={setCheckedActiveUsers}
          setUnconfirmedUsers={setUnconfirmedUsers}
          setPendingInvites={setPendingInvites}
        />
      )}
      <TabSelection
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filter={filter}
        setFilter={setFilter}
        setOpenSideActionBoard={setOpenSideActionBoard}
      />
      <div style={{ gap: '64px' }} className="flex flex-col">
        {(currentTab === 'Active Users' || searchQuery !== '') && (
          <ActiveUsersTable
            searchQuery={debouncedSearch}
            filter={filter}
            checkedActiveUsers={checkedActiveUsers}
            setCheckedActiveUsers={setCheckedActiveUsers}
            setOpenSideActionBoard={setOpenSideActionBoard}
          />
        )}
        {(currentTab === 'Unconfirmed Users' || searchQuery !== '') && (
          <UnconfirmedUsersTable
            searchQuery={debouncedSearch}
            filter={filter}
            unconfirmedUsers={unconfirmedUsers}
            setUnconfirmedUsers={setUnconfirmedUsers}
            setOpenSideActionBoard={setOpenSideActionBoard}
          />
        )}
        {(currentTab === 'Pending Invites' || searchQuery !== '') && (
          <PendingInvitesTable
            searchQuery={debouncedSearch}
            filter={filter}
            pendingInvites={pendingInvites}
            setPendingInvites={setPendingInvites}
            setOpenSideActionBoard={setOpenSideActionBoard}
          />
        )}
      </div>
    </div>
  );
};

export default AdminUser;
