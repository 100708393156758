import React, { useState } from 'react';
import TabSelection from './components/TabSelection';
import SideBar from './components/sidebar';
import Examples from './Examples';

const tabs = [
  {
    label: 'Examples',
    show: { search: true, filters: { date: false, email: false } },
  },
];

const Tabs = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({});
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [openSideActionBoard, setOpenSideActionBoard] = useState(false);

  return (
    <div>
      {openSideActionBoard && (
        <SideBar
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      )}
      <TabSelection
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filter={filter}
        setFilter={setFilter}
        setOpenSideActionBoard={setOpenSideActionBoard}
      />
      <div style={{ gap: '64px' }} className="flex flex-col">
        {currentTab?.label === 'Examples' && (
          <Examples
            searchQuery={searchQuery}
            filter={filter}
            setOpenSideActionBoard={setOpenSideActionBoard}
          />
        )}
      </div>
    </div>
  );
};

export default Tabs;
