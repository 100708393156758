import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { formatDate } from '../../../helpers';

const columns = [
  {
    label: 'Date',
    field: 'date',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '15%',
  },
  {
    label: 'New Users',
    field: 'total_new_users',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '15%',
  },
  {
    label: 'Chats',
    field: 'total_chats',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Messages to Alex',
    field: 'total_messages_to_alex',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '15%',
  },
  {
    label: 'Msg/Chat',
    field: 'msg_per_chat',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Likes',
    field: 'total_liked_messages',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'Dislikes',
    field: 'total_disliked_messages',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: '1 Day',
    field: 'conversations_1_day',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: '3 Days',
    field: 'conversations_3_days',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: '7 Days',
    field: 'conversations_7_days',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
];

const renderRowFn = (data) => {
  return {
    date: formatDate(data?.date),
    total_users: data?.total_users,
    total_new_users: data?.total_new_users,
    total_chats: data?.total_chats,
    total_messages_to_alex: data?.total_messages_to_alex,
    total_liked_messages: data?.total_liked_messages,
    total_disliked_messages: data?.total_disliked_messages,
    conversations_1_day: data?.conversations_1_day,
    conversations_3_days: data?.conversations_3_days,
    conversations_7_days: data?.conversations_7_days,
    msg_per_chat: data?.msg_per_chat,
  };
};

const AlexUsage = ({ searchQuery, filter }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch } = useQuery(
    [
      'getReportDailyAlexUsage',
      { page: currentPage, search: searchQuery, filter },
    ],
    adminAPI.getReportDailyAlexUsage
  );

  useEffect(() => {
    if (
      data?.pagination?.current_page &&
      currentPage !== data?.pagination?.current_page
    ) {
      setCurrentPage(data.pagination.current_page);
    }
  }, [data?.pagination?.current_page, currentPage]);

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.reports && (
        <Table
          data={data?.reports}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default AlexUsage;
