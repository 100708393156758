import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../../../api';
import SVG from 'react-inlinesvg';
import ChevronRightIcon from '../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';

const OrderDetails = ({ id, openSideActionBoard, setOpenSideActionBoard }) => {
  const { data: order, refetch } = useQuery(
    ['getOrderDetails', { id }],
    adminAPI.getOrderDetails
  );

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const handlePrint = () => {
    const printContent = document.getElementById(
      'order-details-print'
    ).innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  };

  return (
    <>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>

      <div
        id="order-details-print"
        className="order-details-container bg-white p-8 rounded-lg max-w-3xl mx-auto"
      >
        <div className="order-header text-left mb-6">
          <h1 className="text-3xl font-bold text-gray-800">Order Details</h1>
        </div>

        <div className="bill-to mb-6">
          <h3 className="font-semibold text-gray-700">Bill To</h3>
          <p className="text-gray-800 font-medium">
            {order?.billing_details.name}
          </p>
          <p className="text-gray-800">
            {order?.billing_details.address.street}
          </p>
          <p className="text-gray-800">
            {order?.billing_details.address.city}{' '}
            {order?.billing_details.address.state}{' '}
            {order?.billing_details.address.postal_code}
          </p>
          <p className="text-gray-800">{order?.billing_details.email}</p>
        </div>

        <table className="w-full mb-6 border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-4 text-left text-gray-600 font-semibold">
                Item #
              </th>
              <th className="p-4 text-left text-gray-600 font-semibold">
                Description
              </th>
              <th className="p-4 text-right text-gray-600 font-semibold">
                Qty
              </th>
              <th className="p-4 text-right text-gray-600 font-semibold m-width-20">
                Unit Price
              </th>
              <th className="p-4 text-right text-gray-600 font-semibold">
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {order?.items.map((item, index) => (
              <tr key={index}>
                <td className="p-4 border-t border-gray-200">{item?.sku}</td>
                <td className="p-4 border-t border-gray-200">
                  {item?.description}
                </td>
                <td className="p-4 border-t border-gray-200 text-right">
                  {item?.quantity}
                </td>
                <td className="p-4 border-t border-gray-200 text-right">
                  ${item?.unit_price}
                </td>
                <td className="p-4 border-t border-gray-200 text-right">
                  ${item?.total_price}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="price-details mb-6">
          <p className="flex justify-between ">
            <span className="text-purple-dark font-bold">
              Preferred Rate Discount
            </span>
            <span className="text-gray-700">
              - {order?.totals?.discount > 0 ? order?.totals?.discount : 'Free'}
            </span>
          </p>
          <p className="flex justify-between text-gray-700">
            <span>Subtotal</span>
            <span>${order?.totals.subtotal}</span>
          </p>
          <p className="flex justify-between text-gray-700">
            <span>Tax</span>
            <span>${order?.totals.tax}</span>
          </p>
          <p className="flex justify-between font-bold text-gray-800">
            <span>Total</span>
            <span>${order?.totals.total}</span>
          </p>
        </div>
      </div>

      <div className="flex justify-end gap-4 mr-6">
        <button
          onClick={handlePrint}
          className="px-4 py-2 bg-grey rounded hover:bg-grey-dark"
        >
          Print Order Details
        </button>
        <button
          onClick={() => (window.location.href = '/')}
          className="px-4 py-2 bg-purple rounded text-white hover:bg-purple-dark"
        >
          View Modules
        </button>
      </div>
    </>
  );
};

export default OrderDetails;
