import React, { useCallback, useState } from 'react';
import { adminAPI } from '../../api';

const EditChat = ({ onClose, conversation }) => {
  const [title, setTitle] = useState(conversation?.title);

  const saveTitle = useCallback(
    async (e) => {
      e.preventDefault();
      await adminAPI.editConversationTitle(conversation?.id, title);
      onClose(undefined, true, title);
    },
    [conversation?.id, title]
  );

  return (
    <div className="chat-modal">
      <div className="chat-modal-body">
        <h3 className="text-2xl">Rename this chat</h3>
        <form action="" onSubmit={saveTitle}>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="flex justify-end gap-4 pt-4">
            <button
              type="button"
              className="chat-modal-button"
              onClick={onClose}
            >
              <span>Cancel</span>
            </button>
            <button type="submit" className="chat-modal-button">
              <span>Rename</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditChat;
