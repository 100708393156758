import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { adminAPI } from '../../../../api';

const BehaviorItem = ({ tenant, behavior, moduleId }) => {
  const { slug } = useParams();
  const tenantBehavior = useMemo(
    () =>
      tenant.tenant_behaviors.find(
        (tenantBehavior) =>
          tenantBehavior.behavior_id == behavior.id &&
          tenantBehavior.course_id == moduleId &&
          tenantBehavior.enabled
      ),
    [tenant, behavior]
  );

  const [toggled, setToggled] = useState(!!tenantBehavior?.enabled);

  const onHandleToogle = useCallback(() => {
    if (!toggled) {
      adminAPI.enableTenantBehavior({
        course: moduleId,
        behavior: behavior.id,
        slug,
      });
    } else {
      adminAPI.disableTenantBehavior({
        course: moduleId,
        behavior: behavior.id,
        slug,
      });
    }
    setToggled(!toggled);
  }, [toggled]);

  return (
    <div key={behavior.id}>
      <div
        className="py-4 border-b border-gray flex items-center relative pl-4"
        onClick={onHandleToogle}
      >
        <input
          type="checkbox"
          name="behavior"
          className="mx-1"
          checked={toggled}
        />
        <p>{behavior.title}</p>
      </div>
    </div>
  );
};

export default BehaviorItem;
