import React, { useCallback, useEffect, useRef, useState } from 'react';

const CellInput = ({ title, type = 'string', onChangeCells }) => {
  const [cells, setCells] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef(null);

  useEffect(() => onChangeCells(cells), [cells]);

  const onChangeInput = useCallback(
    (e) => {
      const { value } = e.target;
      const newCells = value.split(',').map((cell) => cell.trim());
      if (inputValue.length + 1 < value.length) {
        setCells([...cells, ...newCells]);
        setInputValue('');
      } else if (newCells.length > 1) {
        const currentInput = newCells.pop();
        const currentCells = [...cells, ...newCells];
        setCells(currentCells);
        setInputValue(currentInput);
      } else {
        setInputValue(value);
      }
    },
    [cells, inputValue]
  );

  const onBlurInput = useCallback(
    (e) => {
      const { value } = e.target;
      if (value.trim().length > 0) {
        setCells([...cells, value]);
        setInputValue('');
      }
    },
    [cells]
  );

  const onRemoveCell = useCallback((cellToRemove) => {
    setCells((currentCells) =>
      currentCells.filter((cell) => cell !== cellToRemove)
    );
  }, []);

  return (
    <div>
      <p className="text-sm uppercase text-charcoal font-bold">{title}</p>
      <div className="email-list w-full p-4 flex flex-wrap border-2 border-gray rounded-lg relative mt-4">
        {cells.map((cell, index) => (
          <div
            className="email-item shadow flex px-1 items-center rounded"
            key={index}
          >
            {cell}
            <button
              type="button"
              className="ml-2 flex items-center justify-center"
              onClick={() => onRemoveCell(cell)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  stroke="#6357B5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 6 6 18M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        ))}

        <input
          className="email-input outline-none bg-transparent border-none"
          type={type}
          ref={inputRef}
          value={inputValue}
          onChange={onChangeInput}
          onBlur={onBlurInput}
        />
      </div>
    </div>
  );
};

export default CellInput;
