import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../../api';
import Pagination from '../../../../components/Pagination';
import Table from '../../../../components/Table';
import EditIcon from '../../../../../../../assets/images/reskin-images/icon--edit.svg';
import BinIcon from '../../../../../../../assets/images/reskin-images/icon--bin.svg';
import SVG from 'react-inlinesvg';
import NoImage from '../../../../../../../assets/images/reskin-images/img--no-picture.jpeg';
import RemoveModal from './components/sidebar/DeleteModal';
import useModal from '../../../../context/ModalContext';
import { useParams } from 'react-router-dom';

const columns = [
  {
    label: 'Map Points',
    field: 'description',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '100%',
  },
];

const BehaviorMaps = ({ searchQuery, filter, setOpenSideActionBoard }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowHovered, setRowHovered] = useState(false);
  const { setContent } = useModal();
  const { behaviorId } = useParams();

  const renderRowFn = (behaviorMap) => {
    const id = behaviorMap?.id;

    const actionButtons = (
      <div
        className={` absolute pin-r pin-t h-full items-center pr-2 ${
          rowHovered === id ? 'flex' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="mr-2 p-2 bg-purple-100 rounded-lg hover:bg-purple-200"
          onClick={() =>
            setOpenSideActionBoard({
              type: 'behavior_maps',
              action: 'edit',
              ids: [id],
            })
          }
        >
          <SVG src={EditIcon} />
        </button>
        <button
          type="button"
          className="flex items-center justify-content-center bg-purple-100 hover:bg-red-lighter rounded-lg p-2"
          onClick={() => {
            setContent({
              content: (
                <RemoveModal
                  id={id}
                  refetch={refetch}
                  behaviorId={behaviorId}
                />
              ),
            });
          }}
        >
          <SVG src={BinIcon} />
        </button>
      </div>
    );

    console.log({ image: behaviorMap });

    return {
      id,
      description: (
        <div className="w-full flex ml-4 items-center">
          <img
            src={behaviorMap.thumbnail || NoImage}
            alt={`Thumbnail for ${behaviorMap.description}`}
            className="w-32 h-32 object-cover mr-4"
          />
          <div className="flex justify-between items-center ml-4 min-w-[80%]">
            <p className={`mb-4 mr-24`}>{behaviorMap.description}</p>
            <div className="mt-2">{actionButtons}</div>
          </div>
        </div>
      ),
    };
  };

  const { data, refetch } = useQuery(
    [
      'getAllBehaviorMaps',
      { page: currentPage, search: searchQuery, filter, behaviorId },
    ],
    adminAPI.getAllBehaviorMaps
  );

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.behavior_maps && (
        <Table
          id="behavior_maps"
          data={data?.behavior_maps}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
          setRowHovered={setRowHovered}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPage={data?.pagination?.total_pages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default BehaviorMaps;
