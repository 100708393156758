import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import SVG from 'react-inlinesvg';
import ChevronRightIcon from '../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';
import { adminAPI } from '../../../../../api';
import FormLoader from '../../../../../components/FormLoader';
import useAlarm from '../../../../../context/AlarmContext';
import { useNavigate } from 'react-router-dom';

const BehaviorForm = ({ id, type, setOpenSideActionBoard }) => {
  const { setAlarm } = useAlarm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    sku: '',
    price_cents: '',
    description: '',
    poster: null,
    player_uuid: '',
    audio_uuid: '',
    video_url: '',
    enabled: false,
    counts: {
      behavior_maps: 0,
      examples: 0,
      discussion_questions: 0,
      exercises: 0,
    },
  });

  const { data: behavior, refetch } = useQuery(
    ['getBehaviorDetails', { id }],
    adminAPI.getBehaviorDetails,
    {
      enabled: type === 'edit' || type === 'view',
    }
  );

  useEffect(() => {
    if (type === 'edit' || type === 'view') {
      refetch();
    }
  }, [id, refetch, type]);

  useEffect(() => {
    if (behavior) {
      setFormData({
        title: behavior.title || '',
        subtitle: behavior.subtitle || '',
        sku: behavior.sku || '',
        price_cents: (behavior.price_cents / 100).toFixed(2),
        description: behavior.description || '',
        poster: behavior.thumbnail_url || null,
        player_uuid: behavior.player_uuid || '',
        video_url: behavior.video_url || '',
        audio_uuid: behavior.audio_uuid || '',
        enabled: behavior.enabled || false,
        counts: {
          behavior_maps: behavior?.counts?.behavior_maps || 0,
          examples: behavior?.counts?.examples || 0,
          discussion_questions: behavior?.counts?.discussion_questions || 0,
          exercises: behavior?.counts?.exercises || 0,
        },
      });
    }
  }, [behavior]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      poster: e.target.files[0],
    }));
  };

  const { mutate: createBehaviorMutation, isLoading: createLoading } =
    useMutation(adminAPI.createBehavior, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Behavior created successfully' });
        window.location.reload();
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const { mutate: updateBehaviorDetailsMutation, isLoading: updateLoading } =
    useMutation(adminAPI.updateBehaviorDetails, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Behavior updated successfully' });
        window.location.reload();
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const behaviorData = {
      ...formData,
      id,
      price_cents: parseFloat(formData.price_cents) * 100,
    };

    if (type === 'create') {
      createBehaviorMutation(behaviorData);
    } else if (type === 'edit') {
      updateBehaviorDetailsMutation({ id, behaviorData });
    }
  };

  const isDetailView = type === 'view';

  const label = {
    view: 'View Behavior',
    edit: 'Edit Behavior',
    create: 'Create Behavior',
  };

  const handleNavigation = (path) => {
    setOpenSideActionBoard();
    navigate(`${path}/${id}`);
  };

  return (
    <>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>

      <form
        className="overflow-y-scroll h-full px-8 py-8"
        onSubmit={handleSubmit}
      >
        <h1>{label[type]}</h1>

        <div style={{ gap: '16px' }} className="flex flex-col my-12">
          <div className="mb-6">
            <label className="font-semibold text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Subtitle</label>
            <input
              type="text"
              name="subtitle"
              value={formData.subtitle}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>
          <div className="mb-6">
            <label className="font-semibold text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              rows="4"
              disabled={isDetailView}
            />
          </div>
          <div className="flex mb-6">
            <div className="w-1/2 mb-6 pr-2">
              <label className="font-semibold text-gray-700">SKU</label>
              <input
                type="text"
                name="sku"
                value={formData.sku}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={isDetailView}
              />
            </div>

            <div className="w-1/2 mb-6 pl-2">
              <label className="font-semibold text-gray-700">Price</label>
              <input
                type="text"
                name="price_cents"
                value={formData.price_cents}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={isDetailView}
              />
            </div>
          </div>

          <div className="flex mb-6">
            <div className="w-1/2 pr-2">
              <label className="font-semibold text-gray-700">
                Vidyard Player UUID
              </label>
              <input
                type="text"
                name="player_uuid"
                value={formData.player_uuid}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={isDetailView}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="font-semibold text-gray-700">
                Vidyard Audio Only UUID
              </label>
              <input
                type="text"
                name="audio_uuid"
                value={formData.audio_uuid}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={isDetailView}
              />
            </div>
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Poster Image</label>
            <input
              type="file"
              name="poster"
              onChange={handleFileChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          {type !== 'create' && (
            <div className="mb-6">
              <label className="font-semibold text-gray-700 mb-2">
                Behavior Preview
              </label>
              <div className="w-full bg-gray-200" style={{ height: '360px' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src={formData.video_url}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          )}

          <div className="flex justify-between mb-6">
            <label className="font-semibold text-gray-700">
              Behavior Map Points ({formData.counts?.behavior_maps})
            </label>
            <button
              className="w-48 ml-4 p-2 bg-purple-500 text-white rounded-lg"
              onClick={() => handleNavigation(`maps`)}
            >
              Edit Behavior Map
            </button>
          </div>

          <div className="flex justify-between mb-6">
            <label className="font-semibold text-gray-700">
              Examples ({formData.counts?.examples})
            </label>
            <button
              className="w-48 ml-4 p-2 bg-purple-500 text-white rounded-lg"
              onClick={() => handleNavigation(`examples`)}
            >
              Edit Examples
            </button>
          </div>

          <div className="flex justify-between mb-6">
            <label className="font-semibold text-gray-700">
              Discussion Questions ({formData.counts?.discussion_questions})
            </label>
            <button
              className="w-48 ml-4 p-2 bg-purple-500 text-white rounded-lg"
              onClick={() => handleNavigation(`questions`)}
            >
              Edit Questions
            </button>
          </div>

          <div className="flex justify-between mb-6">
            <label className="font-semibold text-gray-700">
              Exercises ({formData.counts?.exercises})
            </label>
            <button
              className="w-48 ml-4 p-2 bg-purple-500 text-white rounded-lg"
              onClick={() => handleNavigation(`exercises`)}
            >
              Edit Exercises
            </button>
          </div>

          <div className="flex items-center mb-6">
            <input
              type="checkbox"
              name="enabled"
              checked={formData.enabled}
              onChange={(e) =>
                setFormData({ ...formData, enabled: e.target.checked })
              }
              disabled={isDetailView}
              className="mr-2"
            />
            <label className="font-semibold text-gray-700">
              Enable behavior?
            </label>
          </div>
        </div>

        {!isDetailView && (
          <button
            className="mb-20 p-4 bg-purple-500 text-white font-semibold rounded-lg hover:bg-purple-700 transition-colors"
            type="submit"
          >
            {type === 'create' ? 'Create Behavior' : 'Update Behavior'}
          </button>
        )}

        {(createLoading || updateLoading) && <FormLoader />}
      </form>
    </>
  );
};

export default BehaviorForm;
