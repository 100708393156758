import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../api';
import Plus from '../../../../../assets/images/reskin-images/icon--plus.svg';
import chatLoading from '../../../../../assets/images/chat-loading.svg';
import SavedChatItem from './SavedChatItem';
import EditChat from './EditChat';
import RemoveChat from './RemoveChat';

const SavedChats = ({
  onSelectConversation,
  onStartNewChat,
  currentSessionId,
  setIsFirstAccess,
}) => {
  const [editChat, setEditChat] = useState(false);
  const [removeChat, setRemoveChat] = useState(false);
  const [currentConversation, setCurrentConversation] = useState();
  const [conversations, setConversations] = useState([]);
  const [offset, setOffset] = useState(0);

  const { isLoading, data, refetch } = useQuery(
    ['getConversation', { offset }],
    () => adminAPI.getConversations(offset),
    {
      onSuccess: (data) => {
        if (data?.conversations) {
          const newConversations = data.conversations.filter(
            (newConv) =>
              !conversations.some(
                (existingConv) => newConv.id === existingConv.id
              )
          );
          const sortedConversations = [
            ...newConversations,
            ...conversations,
          ].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
          setConversations(sortedConversations);
          setIsFirstAccess(data.isFirstAccess);
        }
      },
    }
  );

  useEffect(() => {
    if (currentSessionId) {
      refetch();
    }
  }, [currentSessionId]);

  const handleEditChat = useCallback(
    (conversation, refetchConversations, title) => {
      const conversationToRename = currentConversation;
      setCurrentConversation(conversation);
      setEditChat(!editChat);
      if (refetchConversations) {
        conversationToRename.title = title;
        conversationToRename.updated_at = new Date();
        const newConversations = [
          conversationToRename,
          ...conversations.filter(
            (newConv) => newConv.id !== conversationToRename.id
          ),
        ];
        setConversations(newConversations);
      }
    },
    [editChat, currentConversation, conversations]
  );

  const handleRemoveChat = useCallback(
    (conversation, refetchConversations) => {
      const conversationToDelete = currentConversation;
      setCurrentConversation(conversation);
      setRemoveChat(!removeChat);
      if (refetchConversations) {
        const newConversations = conversations.filter(
          (newConv) => newConv.id !== conversationToDelete.id
        );
        setConversations(newConversations);
        onStartNewChat();
      }
    },
    [removeChat, currentConversation, conversations]
  );

  return (
    <>
      <div className="pt-8 pr-3">
        <button type="button" className="chat-new" onClick={onStartNewChat}>
          <span>New chat</span>
          <img src={Plus} width="24px" height="24px" alt="New chat" />
        </button>
      </div>
      <div className="chat-list">
        {conversations?.map((conversation) => (
          <SavedChatItem
            conversation={conversation}
            isCurrent={conversation.session_id === currentSessionId}
            onSelectConversation={onSelectConversation}
            handleEditChat={() => handleEditChat(conversation)}
            handleRemoveChat={() => handleRemoveChat(conversation)}
          />
        ))}
        {conversations?.length < data?.count && (
          <div className="pt-8">
            <button
              type="button"
              className="chat-load"
              onClick={() => setOffset((previous) => previous + 5)}
            >
              <span>Load more</span>
              {isLoading && (
                <img
                  className="ml-4"
                  src={chatLoading}
                  width="24px"
                  height="24px"
                  alt="Loading"
                />
              )}
            </button>
          </div>
        )}
      </div>

      {editChat && (
        <EditChat onClose={handleEditChat} conversation={currentConversation} />
      )}

      {removeChat && (
        <RemoveChat
          onClose={handleRemoveChat}
          conversation={currentConversation}
        />
      )}
    </>
  );
};

export default SavedChats;
