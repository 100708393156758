import React, { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';

import AlexAI from '../../../../../assets/images/chat-alex-icon.svg';
import chatLoading from '../../../../../assets/images/chat-loading.svg';
import thumbsUp from '../../../../../assets/images/thumbs-up.png';
import thumbsDown from '../../../../../assets/images/thumbs-down.png';
import { adminAPI } from '../../api';

const NEUTRAL = 0;
const LIKED = 1;
const DISLIKED = 2;

const Recipient = ({ message }) => {
  const [liked, setLiked] = useState(NEUTRAL);
  const [showOpinionTextarea, setShowOpinionTextarea] = useState(false);
  const [opinion, setOpinion] = useState(message?.feedback?.feedback || '');
  const [feedback, setFeedback] = useState(message?.feedback);
  const [sendingFeedback, setSendingFeedback] = useState(false);

  useEffect(() => {
    if (message?.feedback) {
      setLiked(message.feedback.liked ? 1 : 2);
      setShowOpinionTextarea(false);
      setOpinion(message.feedback.feedback);
      setFeedback(message.feedback);
    } else {
      setLiked(NEUTRAL);
      setOpinion('');
      setShowOpinionTextarea(false);
      setFeedback(undefined);
      setSendingFeedback(false);
    }
  }, [message]);

  const handleLikeClick = useCallback(() => {
    setFeedback(undefined);
    setOpinion('');
    if (liked === LIKED) {
      setLiked(NEUTRAL);
      setShowOpinionTextarea(false);
      adminAPI.deleteMessageFeedback({
        message_id: message.id,
      });
    } else {
      setLiked(LIKED);
      setShowOpinionTextarea(true);
      adminAPI.sendMessageFeedback({
        liked: true,
        feedback: undefined,
        message_id: message.id,
      });
    }
  }, [liked]);

  const handleDislikeClick = useCallback(() => {
    setFeedback(undefined);
    setOpinion('');
    if (liked === DISLIKED) {
      setLiked(NEUTRAL);
      setShowOpinionTextarea(false);
      adminAPI.deleteMessageFeedback({
        message_id: message.id,
      });
    } else {
      setLiked(DISLIKED);
      setShowOpinionTextarea(true);
      adminAPI.sendMessageFeedback({
        liked: false,
        feedback: undefined,
        message_id: message.id,
      });
    }
  }, [liked]);

  const handleOpinionTextarea = () => {
    setShowOpinionTextarea(!showOpinionTextarea);
  };
  const handleOpinionChange = (event) => {
    setOpinion(event.target.value);
  };
  const handleSubmitOpinion = async (e) => {
    e.preventDefault();
    setSendingFeedback(true);
    const response = await adminAPI.sendMessageFeedback({
      liked: liked === LIKED,
      feedback: opinion,
      message_id: message.id,
    });
    setSendingFeedback(false);
    setFeedback(response);
  };
  return (
    <div className="chat-row recipient">
      <div className="recipient-picture">
        <img src={AlexAI} width="24px" height="24px" alt="Alex AI" />
      </div>
      <div className="recipient-message">
        {message?.message ? (
          parse(message.message)
        ) : (
          <img src={chatLoading} width="24px" height="24px" alt="Loading" />
        )}
      </div>
      {message?.message && (
        <div className="chat-feedback">
          <div className="flex items-center gap-2">
            <button
              type="button"
              className={`${
                liked === LIKED
                  ? 'chat-feedback-like active'
                  : 'chat-feedback-like'
              }`}
              onClick={handleLikeClick}
              enabled={!feedback}
            >
              <img src={thumbsUp} alt="Like" />
            </button>
            <button
              type="button"
              className={`${
                liked === DISLIKED
                  ? 'chat-feedback-dislike active'
                  : 'chat-feedback-dislike'
              }`}
              onClick={handleDislikeClick}
              enabled={!feedback}
            >
              <img src={thumbsDown} alt="Delike" />
            </button>
          </div>
          {liked !== NEUTRAL && showOpinionTextarea ? (
            <>
              <form>
                {feedback ? (
                  <div className="flex items-center justify-between">
                    <h4 className="font-bold">Thanks for the feedback!</h4>
                    <div
                      className="relative flex items-center justify-center w-8 h-8 cursor-pointer"
                      onClick={handleOpinionTextarea}
                    >
                      <span
                        style={{ height: '2px', transform: 'rotate(45deg)' }}
                        className="absolute w-5 rounded bg-black"
                      />
                      <span
                        style={{ height: '2px', transform: 'rotate(-45deg)' }}
                        className="absolute w-5 rounded bg-black"
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center justify-between">
                      <h4 className="font-bold">
                        Why did you choose this rating? (optional)
                      </h4>
                      <div
                        className="relative flex items-center justify-center w-8 h-8 cursor-pointer"
                        onClick={handleOpinionTextarea}
                      >
                        <span
                          style={{ height: '2px', transform: 'rotate(45deg)' }}
                          className="absolute w-5 rounded bg-black"
                        />
                        <span
                          style={{ height: '2px', transform: 'rotate(-45deg)' }}
                          className="absolute w-5 rounded bg-black"
                        />
                      </div>
                    </div>
                    <textarea
                      value={opinion}
                      onChange={handleOpinionChange}
                      enabled={!sendingFeedback}
                    />
                    <button
                      type="submit"
                      className="submit"
                      onClick={handleSubmitOpinion}
                      enabled={!sendingFeedback}
                    >
                      <span className="text-xs uppercase font-bold">
                        Submit
                      </span>
                    </button>
                  </>
                )}
              </form>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Recipient;
