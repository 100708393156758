import React, { useCallback, useState } from 'react';
import { adminAPI } from '../../../../api';
import UserForm from './UserForm';

const CreateUser = ({ tenant, onCreated, onClose }) => {
  const [errors, setErrors] = useState([]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const response = await adminAPI.createTenantUser({
        data,
        slug: tenant.slug,
      });
      if (response.success === false) {
        setErrors(response.errors);
      } else {
        onCreated();
      }
    },
    [tenant]
  );

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        New User
      </h3>
      <UserForm
        onSubmitForm={handleFormSubmit}
        onCloseForm={onClose}
        errors={errors}
      />
    </div>
  );
};

export default CreateUser;
