import React, { useCallback, useState } from 'react';
import SearchInput from '../../../components/SearchInput';
import useAuth from '../../../context/AuthContext';
import ExportCsv from '../../../components/ExportCsv';
import SideActionBoard from '../../../components/SideActionBoard';
import Filters from './Filters';

const TabSelection = ({
  tabs,
  setCurrentTab,
  currentTab,
  searchQuery,
  setSearchQuery,
  filter,
  setFilter,
}) => {
  const { userData, isEmployee, isTenant } = useAuth();
  const isAdmin = userData?.roles?.includes('admin');
  const hasAccess = isAdmin || isEmployee;
  const [toggleFilter, setToggleFilter] = useState(false);

  const onSelectFilters = useCallback(
    (selectedFilters) => setFilter(selectedFilters),
    [filter]
  );

  return (
    <>
      <div className="border-b-2 border-gray flex items-center justify-between mb-6">
        <div className="flex items-center gap-6">
          {hasAccess && (
            <ul className="flex items-center p-0">
              {tabs.map((val) => (
                <div
                  key={val.label}
                  className={`${
                    currentTab?.label === val?.label &&
                    searchQuery === '' &&
                    'border-b-4 border-purple'
                  }`}
                >
                  <button
                    onClick={() => setCurrentTab(val)}
                    type="button"
                    className={`px-4 py-5 ${
                      currentTab?.label === val?.label &&
                      searchQuery === '' &&
                      'font-semibold'
                    }`}
                  >
                    {val.label}
                  </button>
                </div>
              ))}
            </ul>
          )}
          {hasAccess && currentTab?.show?.search && (
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              active={searchQuery !== ''}
            />
          )}
          <p
            className="underline cursor-pointer"
            onClick={() => setToggleFilter(true)}
          >
            {`Filters${
              Object.keys(filter).length > 0
                ? ' (' + Object.keys(filter).length + ')'
                : ''
            }`}
          </p>
        </div>
        <div className="flex">
          {hasAccess && (
            <ExportCsv
              baseUrl="/api/v2/admin/reports"
              dropdownItems={[
                { text: 'Alex Usage', url: '/alex-usage' },
                { text: 'Daily Alex Usage', url: '/daily-alex-usage' },
                { text: 'Invites', url: '/invites' },
              ]}
            />
          )}
        </div>
      </div>
      {toggleFilter && (
        <SideActionBoard setOpenSideActionBoard={() => setToggleFilter(false)}>
          <Filters
            onSelectFilters={onSelectFilters}
            selectedFilters={filter}
            showFilters={currentTab?.show?.filters}
          />
        </SideActionBoard>
      )}
    </>
  );
};

export default TabSelection;
