import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { adminAPI } from '../../../api';
import Button from '../../../components/Button';
import SideActionBoard from '../../../components/SideActionBoard';
import CreateTenant from './CreateTenant';
import SimpleTable from '../../../components/SimpleTable';

import EditIcon from '../../../../../../assets/images/reskin-images/icon--edit.svg';

const columns = [
  {
    label: 'Name',
    field: 'name',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '25%',
    onHover: (item) => (
      <div className={'absolute pin-r pin-t h-full items-center pr-2 flex'}>
        <Link
          className="flex items-center justify-content-center bg-purple-100 p-2 rounded-lg hover:bg-purple-200"
          to={`edit/${item.slug}`}
        >
          <SVG src={EditIcon} />
        </Link>
      </div>
    ),
  },
  {
    label: 'Slug',
    field: 'slug',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '25%',
  },
  {
    label: 'Seats',
    field: 'seats',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '25%',
  },
  {
    label: 'Draft',
    field: 'draft',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '25%',
  },
];

const Tenants = () => {
  const [toggleCreateNew, setToggleCreateNew] = useState(false);

  const { data: tenants, refetch } = useQuery([], adminAPI.getTenants);

  const onTenantCreated = useCallback(() => {
    setToggleCreateNew(false);
    refetch();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <h2
          className="pb-12 pt-12"
          style={{ fontSize: '30px', fontWeight: '800' }}
        >
          Tenants
        </h2>
        <div>
          <Button onClick={() => setToggleCreateNew(!toggleCreateNew)}>
            New Tenant
          </Button>
        </div>
      </div>
      {tenants && (
        <div>
          <SimpleTable data={tenants} columns={columns} />
        </div>
      )}
      {toggleCreateNew && (
        <SideActionBoard
          setOpenSideActionBoard={() => setToggleCreateNew(false)}
        >
          <CreateTenant onTenantCreated={onTenantCreated} />
        </SideActionBoard>
      )}
    </>
  );
};

export default Tenants;
