import React from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../../api';

import ModuleItem from './ModuleItem';

const BehaviorTab = ({ tenant }) => {
  const { data: modules } = useQuery('getCourses', adminAPI.getCourses);
  return (
    <div>
      {modules && (
        <div id="moduleList" className="border-t border-gray">
          {modules.map((module) => (
            <ModuleItem module={module} tenant={tenant} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BehaviorTab;
