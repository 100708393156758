import React from 'react';
import SVG from 'react-inlinesvg';
import EmailIcon from '../../../../../assets/images/reskin-images/icon--email-confirmation.svg';

const ConfirmAlex = () => (
  <div className='h-full z-10 relative'>
    <div
      className='flex flex-col md:flex-row justify-center w-full top-margin px-8'
      style={{ color: '#3C3C3C', gap: '55px' }}
    >
      <div style={{ width: '189px', height: '185px' }}>
        <SVG src={EmailIcon} alt='email-icon' />
      </div>
      <div
        className='flex flex-col justify-center'
        style={{ gap: '20px', maxWidth: '547px' }}
      >
        <p style={{ fontSize: '20px', fontWeight: '700' }}>
          Thank you for signing up.
        </p>
        <h1 style={{ fontSize: '32px', fontWeight: '900' }}>
          Check your email for your confirmation link
        </h1>
        <p style={{ fontSize: '16px', fontWeight: '600' }}>
          Complete your registration and receive access to ALEX.
        </p>
      </div>
    </div>
  </div>
);

export default ConfirmAlex;
