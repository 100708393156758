import React, { useCallback } from 'react';
import { adminAPI } from '../../../api';
import TenantForm from './TenantForm';

const CreateTenant = ({ onTenantCreated }) => {
  const handleFormSubmit = useCallback(async (data) => {
    await adminAPI.createTenant(data);
    onTenantCreated?.();
  }, []);

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        New Tenant
      </h3>
      <TenantForm onSubmitForm={handleFormSubmit} />
    </div>
  );
};

export default CreateTenant;
