import React from 'react';

const PrivacyAlex = () => {
  return (
    <>
      <p className='mb-4'>Last updated: April 16, 2024</p>
      <p className='mb-4'>
        At Admired Leadership<sup>®</sup>, we believe that leadership is defined
        by what you do. Undoubtedly, we believe in honesty, transparency, and
        being upfront in how we treat your information. This privacy policy
        applies to information collected on our Websites, payments, and other
        services provided by Admired Leadership.
      </p>
      <p className='mb-4'>
        If you do not agree with the described Privacy Policy, you should not
        use the Websites. By using the Service, You agree to the collect and use
        of information in accordance with this Privacy Policy. If you have any
        questions about this privacy policy, please email
        support@admiredleadership.com or by mail to Admired Leadership, 4 Radnor
        Corporate Center, Suite 250, Radnor, PA 19087.
      </p>
      <h2 className='mb-4'>Interpretation and Definitions</h2>
      <h4 className='mb-4'>Interpretation</h4>
      <p className='mb-4'>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h4 className='mb-4'>Definitions</h4>
      <p className='mb-4'>For the purposes of this Privacy Policy:</p>
      <div className='ml-8 mb-4'>
        <p className='mb-1'>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable. Under
          GDPR (General Data Protection Regulation), You can be referred to as
          the Data Subject or as the User as you are the individual using the
          Service.
        </p>
        <p className='mb-1'>
          <strong>Company</strong> (referred to as either "the Company”, "We”,
          "Us” or "Our” in this Agreement) refers to Admired Leadership, 4
          Radnor Corporate Center Suite 250, Radnor, PA 19087. For the purpose
          of the GDPR, the Company is the Data Controller.
        </p>
        <p className='mb-1'>
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by or is under common control with a party, where "control”
          means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other
          managing authority.
        </p>
        <p className='mb-1'>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </p>
        <p className='mb-1'>
          <strong>Website</strong> refers to Admired Leadership<sup>®</sup>,
          accessible from{' '}
          <a href='https://admiredleadership.com/' target='_blank'>
            www.admiredleadership.com
          </a>
          .
        </p>
        <p className='mb-1'>
          <strong>Service</strong> refers to the Website.
        </p>
        <p className='mb-1'>
          <strong>Country</strong> refers to the United States.
        </p>
        <p className='mb-1'>
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used. For the purpose of the
          GDPR, Service Providers are considered Data Processors.
        </p>
        <p className='mb-1'>
          <strong>Third-party Social Media Service</strong> refers to any
          website or any social network website through which a User can log in
          or create an account to use the Service.
        </p>
        <p className='mb-1'>
          <strong>Facebook Fan Page</strong> is a public profile named Admired
          Leadership<sup>®</sup> specifically created by the Company on the
          Facebook social network, accessible from{' '}
          <a href='https://facebook.com/admiredleadership' target='_blank'>
            facebook.com/admiredleadership
          </a>
          .
        </p>
        <p className='mb-1'>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual. For the purposes for GDPR,
          Personal Data means any information relating to You such as a name, an
          identification number, location data, online identifier or to one or
          more factors specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity.
        </p>
        <p className='mb-1'>
          For the purposes of the CCPA, Personal Data means any information that
          identifies, relates to, describes or is capable of being associated
          with, or could reasonably be linked, directly or indirectly, with You.
        </p>
        <p className='mb-1'>
          <strong>Cookies</strong> are small files that are placed on Your
          computer, mobile device or any other device by a website, containing
          the details of Your browsing history on that website among its many
          uses.
        </p>
        <p className='mb-1'>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </p>
        <p className='mb-1'>
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </p>
        <p className='mb-1'>
          <strong>Data Controller</strong>, for the purposes of the GDPR
          (General Data Protection Regulation), refers to the Company as the
          legal person which alone or jointly with others determines the
          purposes and means of the processing of Personal Data.
        </p>
        <p className='mb-1'>
          <strong>Do Not Track</strong> (DNT) is a concept that has been
          promoted by US regulatory authorities, in particular the U.S. Federal
          Trade Commission (FTC), for the Internet industry to develop and
          implement a mechanism for allowing internet users to control the
          tracking of their online activities across websites.
        </p>
        <p className='mb-1'>
          <strong>Business</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), refers to the Company as the legal entity that
          collects Consumers’ personal information and determines the purposes
          and means of the processing of Consumers’ personal information, or on
          behalf of which such information is collected and that alone, or
          jointly with others, determines the purposes and means of the
          processing of consumers’ personal information, that does business in
          the State of California.
        </p>
        <p className='mb-1'>
          <strong>Consumer</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), means a natural person who is a California
          resident. A resident, as defined in the law, includes (1) every
          individual who is in the USA for other than a temporary or transitory
          purpose, and (2) every individual who is domiciled in the USA who is
          outside the USA for a temporary or transitory purpose.
        </p>
        <p className='mb-1'>
          <strong>Sale</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), means selling, renting, releasing, disclosing,
          disseminating, making available, transferring, or otherwise
          communicating orally, in writing, or by electronic or other means, a
          Consumer’s Personal information to another business or a third party
          for monetary or other valuable consideration.
        </p>
      </div>
      <h2 className='mb-4'>Collecting and Using Your Personal Data</h2>
      <h3 className='mb-4'>Types of Data Collected</h3>
      <h4 className='mb-4'>Personal Data</h4>
      <p className='mb-4'>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul className='mb-4'>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Usage Data</li>
        <li>
          Information provided during interactions with the AI chat service
          powered by Anthropic
        </li>
      </ul>
      <h4 className='mb-4'>Usage Data</h4>
      <p className='mb-4'>
        Usage Data is collected automatically when using the Service.
      </p>
      <p className='mb-4'>
        Usage Data may include information such as Your Device’s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p className='mb-4'>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p className='mb-4'>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <h3 className='mb-4'>
        Information from Third-Party Social Media Services
      </h3>
      <h4 className='mb-4'>Tracking Technologies and Cookies</h4>
      <p className='mb-4'>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service.
      </p>
      <p className='mb-4'>
        You can instruct Your browser to refuse all Cookies or to indicate when
        a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service.
      </p>
      <p className='mb-4'>
        Cookies can be "Persistent” or "Session” Cookies. Persistent Cookies
        remain on your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close your web browser.
        Learn more about cookies{' '}
        <a href='https://www.termsfeed.com/blog/cookies/' target='_blank'>
          All About Cookies
        </a>
        .
      </p>
      <p className='mb-4'>
        We use both session and persistent Cookies for the purposes set out
        below:
      </p>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>Necessary / Essential Cookies</h4>
        <p className='mb-1'>Type: Session Cookies</p>
        <p className='mb-1'>Administered by: Us</p>
        <p className='mb-2'>
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </p>
        <h4 className='mb-1'>Cookies Policy / Notice Acceptance Cookies</h4>
        <p className='mb-1'>Type: Persistent Cookies</p>
        <p className='mb-1'>Administered by: Us</p>
        <p className='mb-2'>
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </p>
        <h4 className='mb-1'>Functionality Cookies</h4>
        <p className='mb-1'>Type: Session Cookies</p>
        <p className='mb-1'>Administered by: Us</p>
        <p className='mb-2'>
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </p>
        <h4 className='mb-1'>Tracking and Performance Cookies</h4>
        <p className='mb-1'>Type: Persistent Cookies</p>
        <p className='mb-1'>Administered by: Third-Parties</p>
        <p className='mb-2'>
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new advertisements, pages, features or new functionality of the
          Website to see how our users react to them.
        </p>
        <h4 className='mb-1'>Targeting and Advertising Cookies</h4>
        <p className='mb-1'>Type: Persistent Cookies</p>
        <p className='mb-1'>Administered by: Third-Parties</p>
        <p className='mb-2'>
          Purpose: These Cookies track your browsing habits to enable Us to show
          advertising which is more likely to be of interest to You. These
          Cookies use information about your browsing history to group You with
          other users who have similar interests. Based on that information, and
          with Our permission, third party advertisers can place Cookies to
          enable them to show adverts which We think will be relevant to your
          interests while You are on third party websites.
        </p>
      </div>
      <p className='mb-4'>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </p>
      <h3 className='mb-4'>Use of Your Personal Data</h3>
      <p className='mb-4'>
        The Company may use Personal Data for the following purposes:
      </p>
      <ul className='mb-4'>
        <li>
          <strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.
        </li>
        <li>
          <strong>To manage Your Account:</strong> to manage Your registration
          as a user of the Service. The Personal Data You provide can give You
          access to different functionalities of the Service that are available
          to You as a registered user.
        </li>
        <li>
          <strong>For the performance of a contract:</strong> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </li>
        <li>
          <strong>To contact You:</strong> To contact You by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application’s push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </li>
        <li>
          <strong>To provide You</strong> with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </li>
        <li>
          <strong>To manage Your requests:</strong> To attend and manage Your
          requests to Us.
        </li>
        <li>
          <strong>To provide AI chat services:</strong> We may use your Personal
          Data to provide AI chat services powered by Anthropic. Anthropic will
          process your Personal Data in accordance with their{' '}
          <a href='https://www.anthropic.com/privacy' target='_blank'>
            Privacy Policy
          </a>
          .
        </li>
      </ul>
      <p className='mb-4'>
        We may share your personal information in the following situations:
      </p>
      <ul className='mb-4'>
        <li>
          <strong>With Service Providers:</strong> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to show advertisements to You to help support and
          maintain Our Service, to advertise on third party websites to You
          after You visited our Service, for payment processing, to contact You.
        </li>
        <li>
          <strong>For Business transfers:</strong> We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of our business to another company.
        </li>
        <li>
          <strong>With Affiliates:</strong> We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </li>
        <li>
          <strong>With Business partners:</strong> We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          <strong>With other users</strong> when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile
        </li>
      </ul>
      <h3 className='mb-4'>Retention of Your Personal Data</h3>
      <p className='mb-4'>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p className='mb-4'>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <p className='mb-4'>
        For data processed by Anthropic as part of the AI chat service,
        Anthropic will retain your Personal Data in accordance with their data
        retention policy outlined in their Privacy Policy.
      </p>
      <h3 className='mb-4'>Transfer of Your Personal Data</h3>
      <p className='mb-4'>
        Your information, including Personal Data, is processed at the Company’s
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>
      <p className='mb-4'>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p className='mb-4'>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <p className='mb-4'>
        For data processed by Anthropic as part of the AI chat service, your
        information may be transferred to and maintained on computers located
        outside of your state, province, country or other governmental
        jurisdiction where the data protection laws may differ from those of
        your jurisdiction. Your consent to this Privacy Policy represents your
        agreement to that transfer.
      </p>
      <h3 className='mb-4'>Disclosure of Your Personal Data</h3>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>Business Transactions</h4>
        <p className='mb-1'>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h4 className='mb-1'>Law enforcement</h4>
        <p className='mb-1'>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h4 className='mb-1'>Other legal requirements</h4>
        <p className='mb-1'>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <div className='ml-8'>
          <p className='mb-1'>Comply with a legal obligation</p>
          <p className='mb-1'>
            Protect and defend the rights or property of the Company
          </p>
          <p className='mb-1'>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </p>
          <p className='mb-1'>
            Protect the personal safety of Users of the Service or the public
          </p>
          <p className='mb-1'>Protect against legal liability</p>
        </div>
        <h4 className='mb-1'>Security of Your Personal Data</h4>
        <p className='mb-1'>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h4 className='mb-1'>
          Detailed Information on the Processing of Your Personal Data
        </h4>
        <p className='mb-1'>
          Service Providers have access to Your Personal Data only to perform
          their tasks on Our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
      </div>
      <h3 className='mb-4'>AI Chat</h3>
      <p className='mb-4'>
        We may use third-party APIs to offer AI chat services.
      </p>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>ALEX</h4>
        <p className='mb-1'>
          ALEX is an AI chat service provided by Admired Leadership, powered by
          Anthropic's API. It allows Users to interact with an AI assistant to
          enhance their experience on the Admired Leadership platform.
        </p>
        <h4 className='mb-1'>Anthropic</h4>
        <p className='mb-1'>
          Anthropic is an artificial intelligence research company that develops
          advanced AI systems and provides API services. Admired Leadership uses
          Anthropic's API to power its ALEX AI chat service. We do not send your
          contact information to Anthropic's services, but we will send your
          chat messages, and we may send other Personal Data to better customize
          or personalize your chat experience, or to help us understand and
          improve your chat experience. This may include sending your first name
          or details about your use of Admired Leadership features to Anthropic.
          Anthropic's handling of this information is subject to their Privacy
          Policy (
          <a href='https://www.anthropic.com/privacy' target='_blank'>
            www.anthropic.com/privacy
          </a>
          ). For questions regarding Anthropic's practices, please contact them
          directly at privacy@anthropic.com. Please note that the term "You" in
          Anthropic's Privacy Policy refers to Admired Leadership in this
          context, not to You as an individual User.
        </p>
      </div>
      <h3 className='mb-4'>Analytics</h3>
      <p className='mb-4'>
        We may use third-party Service providers to monitor and analyze the use
        of our Service
      </p>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>Google Analytics</h4>
        <p className='mb-1'>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
        </p>
        <p className='mb-1'>
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics
          about visits activity.
        </p>
        <p className='mb-1'>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:{' '}
          <a href='https://policies.google.com/privacy.' target='_blank'>
            policies.google.com/privacy
          </a>
          .
        </p>
      </div>
      <h3 className='mb-4'>Advertising</h3>
      <p className='mb-4'>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </p>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>Google AdSense & DoubleClick Cookie</h4>
        <p className='mb-1'>
          Google, as a third party vendor, uses cookies to serve ads on our
          Service. Google’s use of the DoubleClick cookie enables it and its
          partners to serve ads to our users based on their visit to our Service
          or other websites on the Internet.
        </p>
        <p className='mb-1'>
          You may opt out of the use of the DoubleClick Cookie for
          interest-based advertising by visiting the Google Ads Settings web
          page:{' '}
          <a href='http://www.google.com/ads/preferences/' target='_blank'>
            www.google.com/ads/preferences
          </a>
          .
        </p>
      </div>
      <h3 className='mb-4'>Email Marketing</h3>
      <p className='mb-4'>
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </p>
      <p className='mb-4'>
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </p>
      <div className='ml-8 mb-4'>
        <p className='mb-1'>
          <strong>Mailgun:</strong> Their Privacy Policy can be viewed at{' '}
          <a href='https://www.mailgun.com/privacy-policy/' target='_blank'>
            www.mailgun.com/privacy-policy
          </a>
          .
        </p>
        <p className='mb-1'>
          <strong>HubSpot:</strong> Their Privacy Policy can be viewed at{' '}
          <a href='https://legal.hubspot.com/privacy-policy' target='_blank'>
            legal.hubspot.com/privacy-policy
          </a>
          .
        </p>
      </div>
      <h3 className='mb-4'>Payments</h3>
      <p className='mb-4'>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </p>
      <p className='mb-4'>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <div className='ml-8 mb-4'>
        <p className='mb-1'>
          <strong>Stripe:</strong> Their Privacy Policy can be viewed at{' '}
          <a href='https://stripe.com/us/privacy' target='_blank'>
            stripe.com/us/privacy
          </a>
          .
        </p>
      </div>
      <h3 className='mb-4'>Behavioral Remarketing</h3>
      <p className='mb-4'>
        The Company uses remarketing services to advertise on third party
        websites to You after You visited our Service. We and Our third-party
        vendors use cookies to inform, optimize and serve ads based on Your past
        visits to our Service.
      </p>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>Google Ads (AdWords)</h4>
        <p className='mb-1'>
          Google Ads (AdWords) remarketing service is provided by Google Inc.
        </p>
        <p className='mb-1'>
          You can opt-out of Google Analytics for Display Advertising and
          customise the Google Display Network ads by visiting the Google Ads
          Settings page:{' '}
          <a href='http://www.google.com/settings/ads' target='_blank'>
            www.google.com/settings/ads
          </a>
          .
        </p>
        <p className='mb-1'>
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on –{' '}
          <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>
            tools.google.com/dlpage/gaoptout
          </a>{' '}
          – for your web browser. Google Analytics Opt-out Browser Add-on
          provides visitors with the ability to prevent their data from being
          collected and used by Google Analytics.
        </p>
        <p className='mb-1'>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:{' '}
          <a href='https://policies.google.com/privacy' target='_blank'>
            policies.google.com/privacy
          </a>
          .
        </p>
      </div>
      <div className='ml-8 mb-4'>
        <h4 className='mb-1'>Facebook</h4>
        <p className='mb-1'>
          Facebook remarketing service is provided by Facebook Inc. You can
          learn more about interest-based advertising from Facebook by visiting
          this page:{' '}
          <a
            href='https://www.facebook.com/help/164968693837950'
            target='_blank'
          >
            www.facebook.com/help/164968693837950
          </a>
          .
        </p>
        <p className='mb-1'>
          To opt-out from Facebook’s interest-based ads, follow these
          instructions from Facebook:{' '}
          <a
            href='https://www.facebook.com/help/568137493302217'
            target='_blank'
          >
            www.facebook.com/help/568137493302217
          </a>
          .
        </p>
        <p className='mb-1'>
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioural Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA{' '}
          <a href='http://www.aboutads.info/choices/' target='_blank'>
            www.aboutads.info/choices
          </a>
          , the Digital Advertising Alliance of Canada in Canada{' '}
          <a href='http://youradchoices.ca/' target='_blank'>
            youradchoices.ca
          </a>{' '}
          or the European Interactive Digital Advertising Alliance in Europe{' '}
          <a href='http://www.youronlinechoices.eu/'>
            www.youronlinechoices.eu
          </a>{' '}
          or opt-out using your mobile device settings.
        </p>
        <p className='mb-1'>
          For more information on the privacy practices of Facebook, please
          visit Facebook’s Data Policy:{' '}
          <a
            href='https://www.facebook.com/privacy/explanation'
            target='_blank'
          >
            www.facebook.com/privacy/explanation
          </a>
          .
        </p>
      </div>
      <h3 className='mb-4'>Usage, Performance and Miscellaneous</h3>
      <p className='mb-4'>
        We may use third-party Service Providers to provide better improvement
        of our Service.
      </p>
      <div className='ml-8 mb-4'>
        <p className='mb-1'>
          <strong>HubSpot Support:</strong> Their Privacy Policy can be viewed
          at{' '}
          <a href='https://legal.hubspot.com/privacy-policy' target='_blank'>
            legal.hubspot.com/privacy-policy
          </a>
          .
        </p>
      </div>
      <h2 className='mb-4'>GDPR Privacy</h2>
      <h3 className='mb-4'>
        Legal Basis for Processing Personal Data under GDPR
      </h3>
      <p className='mb-4'>
        We may process Personal Data under the following conditions:
      </p>
      <div className='ml-8 mb-4'>
        <p className='mb-1'>
          <strong>Consent:</strong> You have given Your consent for processing
          Personal Data for one or more specific purposes.
        </p>
        <p className='mb-1'>
          <strong>Performance of a contract:</strong> Provision of Personal Data
          is necessary for the performance of an agreement with You and/or for
          any pre-contractual obligations thereof.
        </p>
        <p className='mb-1'>
          <strong>Legal obligations:</strong> Processing Personal Data is
          necessary for compliance with a legal obligation to which the Company
          is subject.
        </p>
        <p className='mb-1'>
          <strong>Vital interests:</strong> Processing Personal Data is
          necessary in order to protect Your vital interests or of another
          natural person.
        </p>
        <p className='mb-1'>
          <strong>Public interests:</strong> Processing Personal Data is related
          to a task that is carried out in the public interest or in the
          exercise of official authority vested in the Company.
        </p>
        <p className='mb-1'>
          <strong>Legitimate interests:</strong> Processing Personal Data is
          necessary for the purposes of the legitimate interests pursued by the
          Company.
        </p>
      </div>
      <p className='mb-4'>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <h3 className='mb-4'>Your Rights under the GDPR</h3>
      <p className='mb-4'>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights. You have the right
        under this Privacy Policy, and by law if You are within the EU, to:
      </p>
      <div className='ml-8 mb-4'>
        <p className='mb-1'>
          <strong>Request access to Your Personal Data.</strong> The right to
          access, update or delete the information We have on You. Whenever made
          possible, you can access, update or request deletion of Your Personal
          Data directly within Your account settings section. If you are unable
          to perform these actions yourself, please contact Us to assist You.
          This also enables You to receive a copy of the Personal Data We hold
          about You.
        </p>
        <p className='mb-1'>
          <strong>
            Request correction of the Personal Data that We hold about You.
          </strong>{' '}
          You have the right to to have any incomplete or inaccurate information
          We hold about You corrected.
        </p>
        <p className='mb-1'>
          <strong>Object to processing of Your Personal Data.</strong> This
          right exists where We are relying on a legitimate interest as the
          legal basis for Our processing and there is something about Your
          particular situation, which makes You want to object to our processing
          of Your Personal Data on this ground. You also have the right to
          object where We are processing Your Personal Data for direct marketing
          purposes.
        </p>
        <p className='mb-1'>
          <strong>Request erasure of Your Personal Data.</strong> You have the
          right to ask Us to delete or remove Personal Data when there is no
          good reason for Us to continue processing it.
        </p>
        <p className='mb-1'>
          <strong>Request the transfer of Your Personal Data.</strong> We will
          provide to You, or to a third-party You have chosen, Your Personal
          Data in a structured, commonly used, machine-readable format. Please
          note that this right only applies to automated information which You
          initially provided consent for Us to use or where We used the
          information to perform a contract with You.
        </p>
        <p className='mb-1'>
          <strong>Withdraw Your consent.</strong> You have the right to withdraw
          Your consent on using your Personal Data. If You withdraw Your
          consent, We may not be able to provide You with access to certain
          specific functionalities of the Service.
        </p>
      </div>
      <h3 className='mb-4'>Exercising of Your GDPR Data Protection Rights</h3>
      <p className='mb-4'>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </p>
      <p className='mb-4'>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <h2 className='mb-4'>Facebook Fan Page</h2>
      <h3 className='mb-4'>Data Controller for the Facebook Fan Page</h3>
      <p className='mb-4'>
        The Company is the Data Controller of Your Personal Data collected while
        using the Service. As operator of the Facebook Fan Page{' '}
        <a href='https://facebook.com/admiredleadership' target='_blank'>
          facebook.com/admiredleadership
        </a>
        , the Company and the operator of the social network Facebook are Joint
        Controllers.
      </p>
      <p className='mb-4'>
        The Company has entered into agreements with Facebook that define the
        terms for use of the Facebook Fan Page, among other things. These terms
        are mostly based on the Facebook Terms of Service:{' '}
        <a href='https://www.facebook.com/terms.php' target='_blank'>
          www.facebook.com/terms.php
        </a>
        .
      </p>
      <p className='mb-4'>
        Visit the Facebook Privacy Policy{' '}
        <a href='https://www.facebook.com/policy.php' target='_blank'>
          www.facebook.com/policy.php
        </a>{' '}
        for more information about how Facebook manages Personal data or contact
        Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations,
        1601 Willow Road, Menlo Park, CA 94025, United States.
      </p>
      <h2 className='mb-4'>Facebook Insights</h2>
      <p className='mb-4'>
        We use the Facebook Insights function in connection with the operation
        of the Facebook Fan Page and on the basis of the GDPR, in order to
        obtain anonymized statistical data about Our users.
      </p>
      <p className='mb-4'>
        For this purpose, Facebook places a Cookie on the device of the user
        visiting Our Facebook Fan Page. Each Cookie contains a unique identifier
        code and remains active for a period of two years, except when it is
        deleted before the end of this period.
      </p>
      <p className='mb-4'>
        Facebook receives, records and processes the information stored in the
        Cookie, especially when the user visits the Facebook services, services
        that are provided by other members of the Facebook Fan Page and services
        by other companies that use Facebook services.
      </p>
      <p className='mb-4'>
        For more information on the privacy practices of Facebook, please visit
        Facebook Privacy Policy here:{' '}
        <a href='https://www.facebook.com/full_data_use_policy' target='_blank'>
          www.facebook.com/full_data_use_policy
        </a>
        .
      </p>
      <h2 className='mb-4'>CCPA Privacy</h2>
      <h3 className='mb-4'>Your Rights under the CCPA</h3>
      <p className='mb-4'>
        Under this Privacy Policy, and by law if You are a resident of
        California, You have the following rights:
      </p>
      <ul className='mb-4'>
        <li>
          <strong>The right to notice.</strong> You must be properly notified
          which categories of Personal Data are being collected and the purposes
          for which the Personal Data is being used.
        </li>
        <li>
          <strong>The right to access / the right to request.</strong> The CCPA
          permits You to request and obtain from the Company information
          regarding the disclosure of Your Personal Data that has been collected
          in the past 12 months by the Company or its subsidiaries to a
          third-party for the third party’s direct marketing purposes.
        </li>
        <li>
          <strong>The right to say no to the sale of Personal Data.</strong> You
          also have the right to ask the Company not to sell Your Personal Data
          to third parties. You can submit such a request by visiting our "Do
          Not Sell My Personal Information” section or web page.
        </li>
        <li>
          <strong>The right to know about Your Personal Data.</strong> You have
          the right to request and obtain from the Company information regarding
          the disclosure of the following:
          <ul>
            <li>The categories of Personal Data collected.</li>
            <li>The sources from which the Personal Data was collected.</li>
            <li>
              The business or commercial purpose for collecting or selling the
              Personal Data.
            </li>
            <li>
              Categories of third parties with whom We share Personal Data.
            </li>
            <li>
              The specific pieces of Personal Data we collected about You.
            </li>
          </ul>
        </li>
        <li>
          <strong>The right to delete Personal Data.</strong> You also have the
          right to request the deletion of Your Personal Data that have been
          collected in the past 12 months.
        </li>
        <li>
          <strong>The right not to be discriminated against.</strong> You have
          the right not to be discriminated against for exercising any of Your
          Consumer’s rights, including by:
          <ul>
            <li>Denying goods or services to You.</li>
            <li>
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties.
            </li>
            <li>
              Providing a different level or quality of goods or services to
              You.
            </li>
            <li>
              Suggesting that You will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services.
            </li>
          </ul>
        </li>
      </ul>
      <h3 className='mb-4'>Exercising Your CCPA Data Protection Rights</h3>
      <p className='mb-4'>
        In order to exercise any of Your rights under the CCPA, and if you are a
        California resident, You can email or call us or visit our "Do Not Sell
        My Personal Information” section or web page.
      </p>
      <p className='mb-4'>
        The Company will disclose and deliver the required information free of
        charge within 45 days of receiving Your verifiable request. The time
        period to provide the required information may be extended once by an
        additional 45 days when reasonable necessary and with prior notice.
      </p>
      <h3 className='mb-4'>Do Not Sell My Personal Information</h3>
      <p className='mb-4'>
        We do not sell personal information. However, the Service Providers we
        partner with (for example, our advertising partners) may use technology
        on the Service that "sells” personal information as defined by the CCPA
        law.
      </p>
      <p className='mb-4'>
        If you wish to opt out of the use of your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA law, you may do so by following the instructions below.
      </p>
      <p className='mb-4'>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that you use.
      </p>
      <h3 className='mb-4'>Website</h3>
      <p className='mb-4'>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
      </p>
      <ul className='mb-4'>
        <li>From Our "Cookie Consent” notice banner</li>
        <li>Or from Our "CCPA Opt-out” notice banner</li>
        <li>Or from Our "Do Not Sell My Personal Information” notice banner</li>
        <li>Or from Our "Do Not Sell My Personal Information” link</li>
      </ul>
      <p className='mb-4'>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
      </p>
      <h3 className='mb-4'>Mobile Devices</h3>
      <p className='mb-4'>
        Your mobile device may give you the ability to opt out of the use of
        information about the apps you use in order to serve you ads that are
        targeted to your interests:
      </p>
      <ul className='mb-4'>
        <li>
          "Opt out of Interest-Based Ads” or "Opt out of Ads Personalization” on
          Android devices
        </li>
        <li>"Limit Ad Tracking” on iOS devices</li>
      </ul>
      <p className='mb-4'>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on your mobile device.
      </p>
      <h3 className='mb-4'>
        "Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </h3>
      <p className='mb-4'>
        Our Service does not respond to Do Not Track signals.
      </p>
      <p className='mb-4'>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </p>
      <h3 className='mb-4'>
        Your California Privacy Rights (California’s Shine the Light law)
      </h3>
      <p className='mb-4'>
        Under California Civil Code Section 1798 (California’s Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties’ direct marketing
        purposes. If you’d like to request more information under the California
        Shine the Light law, and if you are a California resident, You can
        contact Us using the contact information provided below.
      </p>
      <h3 className='mb-4'>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </h3>
      <p className='mb-4'>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p className='mb-4'>
        To request removal of such data, and if you are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </p>
      <p className='mb-4'>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </p>
      <h3 className='mb-4'>Links to Other Websites</h3>
      <p className='mb-4'>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party’s site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p className='mb-4'>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h3 className='mb-4'>Changes to this Privacy Policy</h3>
      <p className='mb-4'>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p className='mb-4'>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the “Last updated”
        date at the top of this Privacy Policy.
      </p>
      <p className='mb-4'>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h3 className='mb-4'>Contact Us</h3>
      <p className='mb-4'>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <div className='ml-8'>
        <p className='mb-1'>By email: support@admiredleadership.com</p>
        <p className='mb-1'>
          By visiting this page on our website:{' '}
          <a
            href='https://admiredleadership.com/leader-contact/'
            target='_blank'
          >
            admiredleadership.com/leader-contact
          </a>
          .
        </p>
        <p className='mb-1'>By phone number: 610.644.5597</p>
        <p>By mail: 4 Radnor Corporate Center, #250, Radnor, PA 19087</p>
      </div>
    </>
  );
};

export default PrivacyAlex;
