import React, { useState } from 'react';
import SideActionBoard from '../../../../../components/SideActionBoard';

import OrderEdit from './OrderEdit';
import OrderDetails from './OrderDetails';

const Sidebar = ({
  openSideActionBoard,
  setOpenSideActionBoard,
  setCheckedActiveUsers,
  setUnconfirmedUsers,
  setPendingInvites,
}) => {
  const { action, ids } = openSideActionBoard;

  return (
    <SideActionBoard setOpenSideActionBoard={setOpenSideActionBoard}>
      {action === 'view' && ids?.length === 1 && (
        <OrderDetails
          id={ids[0]}
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      )}
      {action === 'edit' && ids?.length === 1 && (
        <OrderEdit
          id={ids[0]}
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      )}
    </SideActionBoard>
  );
};

export default Sidebar;
