import React, { forwardRef, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import CellInput from '../../../../components/forms/inputTypes/CellInput';
import { adminAPI } from '../../../../api';

const today = new Date();
const oneWeekFromToday = new Date(today);
oneWeekFromToday.setDate(today.getDate() + 7);

const InviteUser = ({ onClose }) => {
  const { slug } = useParams();
  const [emails, setEmails] = useState([]);
  const [formData, setFormData] = useState({
    limitedAccess: true,
    fixedExpirationDate: false,
    expirationLength: 7,
    expirationType: 'days',
    expirationDate: oneWeekFromToday,
    skipEmail: false,
  });

  const DatePickerCustom = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="border-2 border-gray rounded-lg p-4"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const sendInvitations = useCallback(async () => {
    await adminAPI.sendTenantUserInvites({ slug, data: formData, emails });
    onClose();
  }, [formData, emails, slug]);

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        Invite Users
      </h3>
      <CellInput
        title="Emails"
        type="email"
        onChangeCells={(cells) => setEmails(cells)}
      />

      <div className="pt-4">
        <div className="flex items-center">
          <p className="text-sm uppercase text-charcoal  font-bold">
            Invitation Access
          </p>
        </div>

        <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
          <button
            onClick={() => {
              setFormData({ ...formData, limitedAccess: true });
            }}
            type="button"
            className={`flex flex-col items-center justify-center py-4 w-1/2 
            ${formData.limitedAccess && 'bg-purple-200'}`}
          >
            <p className="text-sm  font-semibold">Limited</p>
            <p className="text-sm  mt-2">Defaults to one week.</p>
            <p className="text-sm ">Sends "Week Access" email</p>
          </button>
          <button
            onClick={() => {
              setFormData({ ...formData, limitedAccess: false });
            }}
            type="button"
            className={`flex flex-col items-center justify-center py-4 w-1/2
             ${!formData.limitedAccess && 'bg-purple-200'}
            `}
          >
            <p className="text-sm  font-semibold">Unlimited</p>
            <p className="text-sm  mt-2">Defaults to one year.</p>
            <p className="text-sm ">Sends "Welcome" email</p>
          </button>
        </div>
      </div>

      <div className="pt-4">
        <div className="flex items-center">
          <p className="text-sm uppercase text-charcoal  font-bold">
            Expiration Type
          </p>
        </div>

        <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
          <button
            onClick={() => {
              setFormData({ ...formData, fixedExpirationDate: false });
            }}
            type="button"
            className={`flex flex-col items-center justify-center py-4 w-1/2 
      ${!formData.fixedExpirationDate && 'bg-purple-200'}`}
          >
            <p className="text-sm  font-semibold">Fixed Length</p>
            <p className="text-sm  mt-2">User has access for a fixed period</p>
            <p className="text-sm  mt-2">after joining.</p>
          </button>
          <button
            onClick={() => {
              setFormData({ ...formData, fixedExpirationDate: true });
            }}
            type="button"
            className={`flex flex-col items-center justify-center py-4 w-1/2
       ${formData.fixedExpirationDate && 'bg-purple-200'}
      `}
          >
            <p className="text-sm  font-semibold">Fixed Date </p>
            <p className="text-sm  mt-2">
              User has access until selected date.
            </p>
          </button>
        </div>
      </div>

      {!formData.fixedExpirationDate && (
        <div className="pt-4">
          <div className="flex items-center">
            <p className="text-sm uppercase text-charcoal  font-bold">
              Invitation Length
            </p>
          </div>
          <input
            type="number"
            style={{ borderRadius: '12px', minWidth: '100%' }}
            className="px-2 py-2 flex items-center border-2"
            value={formData.expirationLength}
            onChange={(e) =>
              setFormData({ ...formData, expirationLength: e.target.value })
            }
          />

          <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
            <button
              onClick={() => {
                setFormData({ ...formData, expirationType: 'days' });
              }}
              type="button"
              className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                formData.expirationType === 'days' && 'bg-purple-200'
              }`}
            >
              <p className="text-sm  font-semibold">Days</p>
            </button>
            <button
              onClick={() => {
                setFormData({ ...formData, expirationType: 'years' });
              }}
              type="button"
              className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                formData.expirationType === 'years' && 'bg-purple-200'
              }`}
            >
              <p className="text-sm  font-semibold">Years</p>
            </button>
          </div>
        </div>
      )}

      {formData.fixedExpirationDate && (
        <div className="pt-4">
          <div className="flex items-center">
            <p className="text-sm uppercase text-charcoal  font-bold">
              Expiration Date
            </p>
          </div>
          <DatePicker
            selected={formData.expirationDate}
            onChange={(date) => {
              setFormData({ ...formData, expirationDate: date });
            }}
            minDate={new Date()}
            dateFormat="yyyy-MM-dd"
            showPopperArrow={false}
            customInput={<DatePickerCustom />}
          />
        </div>
      )}

      <div className="flex pt-4">
        <input
          id="skip_email"
          name="skip_email"
          type="checkbox"
          checked={formData.skipEmail}
          onChange={(e) =>
            setFormData({ ...formData, skipEmail: e.target.checked })
          }
        />
        <label
          htmlFor="skip_email"
          className="text-sm text-charcoal font-bold pl-4"
        >
          Skip sending email
        </label>
      </div>
      <button
        type="button"
        className="p-4 my-10  bg-purple text-white font-semibold rounded-lg"
        onClick={sendInvitations}
      >
        Send Invitations
      </button>
    </div>
  );
};

export default InviteUser;
