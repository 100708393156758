import React from 'react';

const AgreementAlex = () => {
  return (
    <>
      <p className="mb-4">
        Welcome to Admired Leadership<sup>®</sup>. We’re happy you’re here.
      </p>
      <p className="mb-4">
        When you view our content and use our services, you are agreeing to our
        terms, so please read the User Agreement below.
      </p>
      <p className="mb-4">
        This agreement is a legal contract between you and Admired Leadership.
        You acknowledge that you have read, understood and agree to be bound by
        the terms of this agreement. If you do not agree to this contract, you
        should not use or view Admired Leadership.
      </p>
      <p className="mb-4">
        The User agrees not to present, teach, distribute or in any way share
        the content of Admired Leadership. The User understands and agrees that
        the Admired Leadership content is for personal use and personal
        development only and that any distribution, downloading, re-transmission
        or sharing of the content of Admired Leadership, without the express
        written consent of Admired Leadership, is strictly prohibited
      </p>
      <p className="mb-4">
        By agreeing to use this service, you acknowledge that Admired Leadership
        cannot be held legally responsible for the outcomes of following advice
        provided by ALEX or in our content. Specifically:
      </p>
      <p className="mb-4">
        <strong>No Liability for Consequential Damages:</strong> In no event
        shall Admired Leadership or any of its affiliates, or any of their
        respective officers, directors, employees, or agents be liable for any
        indirect, incidental, special, consequential or punitive damages
        resulting from or related to your use of ALEX or any other services
        provided by Admired Leadership. This includes, but is not limited to,
        damages for loss of profits, goodwill, use, data, or other intangible
        losses, even if Admired Leadership has been advised of the possibility
        of such damages.
      </p>
      <p className="mb-4">
        <strong>Disclaimer of Warranties:</strong> You expressly understand and
        agree that your use of ALEX and all information and content included in
        or accessible from these services is at your sole risk. These services
        are provided on an “as is” and “as available” basis. Admired Leadership
        expressly disclaims all warranties of any kind as to these services and
        all information, products and other content included in or accessible
        from these services, whether express or implied, including, but not
        limited to the implied warranties of merchantability, fitness for a
        particular purpose and non-infringement.
      </p>
      <p className="mb-4">
        <strong>Indemnity:</strong> You agree to indemnify and hold harmless
        Admired Leadership, its officers, directors, employees, and agents, from
        any and all claims, liabilities, damages, losses or expenses, including
        reasonable attorneys’ fees and costs, due to or arising out of your use
        of ALEX and any other services provided by Admired Leadership, your
        violation of this Agreement, or your violation of any rights of another.
      </p>
      <p className="mb-4">
        <strong>Acknowledgement:</strong> By agreeing to these terms, you
        acknowledge that you understand and agree to these limitations and
        disclaimers and that Admired Leadership will not be liable for any
        amounts exceeding the fees paid by you to Admired Leadership for these
        services during the six months preceding any claim.
      </p>
    </>
  );
};

export default AgreementAlex;
