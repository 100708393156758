import React from 'react';

export default function EmailItem({ email, onRemove }) {
  return (
    <div className="email-item shadow flex px-1 items-center rounded">
      {email}
      <button
        type="button"
        className="ml-2 flex items-center justify-center"
        onClick={() => onRemove(email)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            stroke="#6357B5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M18 6 6 18M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}
