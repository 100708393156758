import React, { useCallback } from 'react';
import { adminAPI } from '../../../api';
import TenantForm from './TenantForm';

const EditTenantForm = ({ tenant, onEdited }) => {
  const handleFormSubmit = useCallback(async (data) => {
    await adminAPI.editTenant(data);
    onEdited();
  }, []);

  return <TenantForm onSubmitForm={handleFormSubmit} tenant={tenant} />;
};

export default EditTenantForm;
