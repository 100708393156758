import React from 'react';

import Prompts from './Prompts';

import AlexAI from '../../../../../assets/images/chat-alex-icon.svg';

const Intro = ({ prompts, onPromptSelect }) => (
  <div className="chat-intro">
    {/* <div className="recipient-picture">
      <img src={AlexAI} width="24px" height="24px" alt="Alex AI" />
    </div> */}
    <h2 className="mt-2">Hello, I’m ALEX. How can I help you today?</h2>
    <p className="mt-1 mb-8">
      Type your question or select from popular topics below.
    </p>
    <Prompts list={prompts} onPromptSelect={onPromptSelect} />
  </div>
);

export default Intro;
