import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import SVG from 'react-inlinesvg';
import ChevronRightIcon from '../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';
import { adminAPI } from '../../../../../api';
import FormLoader from '../../../../../components/FormLoader';
import useAlarm from '../../../../../context/AlarmContext';

const WebinarForm = ({ id, type, setOpenSideActionBoard }) => {
  const { setAlarm } = useAlarm();
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    presented_at: '',
    description: '',
    registration_link: '',
    player_uuid: '',
    audio_uuid: '',
  });

  const { data: webinar, refetch } = useQuery(
    ['getWebinar', { id }],
    adminAPI.getWebinar,
    {
      enabled: type === 'edit' || type === 'view',
    }
  );

  useEffect(() => {
    if (type === 'edit' || type === 'view') {
      refetch();
    }
  }, [id, refetch, type]);

  useEffect(() => {
    if (webinar) {
      setFormData({
        title: webinar.title || '',
        subtitle: webinar.subtitle || '',
        presented_at: webinar.presented_at
          ? new Date(webinar.presented_at).toISOString().split('T')[0]
          : '',
        description: webinar.description || '',
        registration_link: webinar.registration_link || '',
        player_uuid: webinar.player_uuid || '',
        audio_uuid: webinar.audio_uuid || '',
      });
    }
  }, [webinar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'presented_at') {
      const formattedDate = new Date(value).toISOString().split('T')[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const { mutate: createWebinarMutation, isLoading: createLoading } =
    useMutation(adminAPI.createWebinar, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Webinar created successfully' });
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const { mutate: updateWebinarMutation, isLoading: updateLoading } =
    useMutation(adminAPI.updateWebinar, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Webinar updated successfully' });
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === 'create') {
      createWebinarMutation(formData);
    } else if (type === 'edit') {
      updateWebinarMutation({ id, webinarData: formData });
    }
  };

  const isDetailView = type === 'view';

  const label = {
    view: 'Webinar Details',
    edit: 'Edit Webinar',
    create: 'Create Webinar',
  };

  return (
    <>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>

      <form
        className="overflow-y-scroll h-full px-8 py-8"
        onSubmit={handleSubmit}
      >
        <h1>{label[type]}</h1>

        <div style={{ gap: '16px' }} className="flex flex-col my-12">
          <div className="mb-6">
            <label className="font-semibold text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Subtitle</label>
            <input
              type="text"
              name="subtitle"
              value={formData.subtitle}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">
              Date of Webinar
            </label>
            <input
              type="date"
              name="presented_at"
              value={formData.presented_at}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              rows="4"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">
              Registration URL
            </label>
            <input
              type="text"
              name="registration_link"
              value={formData.registration_link}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">
              Vidyard Player UUID
            </label>
            <input
              type="text"
              name="player_uuid"
              value={formData.player_uuid}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">
              Vidyard Audio Only UUID
            </label>
            <input
              type="text"
              name="audio_uuid"
              value={formData.audio_uuid}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>
        </div>

        {!isDetailView && (
          <button
            className="mb-20 p-4 bg-purple-500 text-white font-semibold rounded-lg hover:bg-purple-700 transition-colors"
            type="submit"
          >
            {type === 'create' ? 'Create Webinar' : 'Update Webinar'}
          </button>
        )}

        {(createLoading || updateLoading) && <FormLoader />}
      </form>
    </>
  );
};

export default WebinarForm;
