import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import SVG from 'react-inlinesvg';
import ChevronRightIcon from '../../../../../../../../../assets/images/reskin-images/icon--chevrons-right.svg';
import { adminAPI } from '../../../../../../api';
import FormLoader from '../../../../../../components/FormLoader';
import useAlarm from '../../../../../../context/AlarmContext';
import { useParams } from 'react-router-dom';

const ExerciseForm = ({ id, type, setOpenSideActionBoard }) => {
  const { setAlarm } = useAlarm();
  const { behaviorId } = useParams();
  const [formData, setFormData] = useState({
    description: '',
    image: null,
  });

  const { data: exercise, refetch } = useQuery(
    ['getExerciseDetails', { id }],
    adminAPI.getExerciseDetails,
    {
      enabled: type === 'edit' || type === 'view',
    }
  );

  useEffect(() => {
    if (type === 'edit' || type === 'view') {
      refetch();
    }
  }, [id, refetch, type]);

  useEffect(() => {
    if (exercise) {
      setFormData({
        description: exercise.description || '',
        image: exercise.image || null,
      });
    }
  }, [exercise]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      image: e.target.files[0],
    }));
  };

  const { mutate: createExerciseMutation, isLoading: createLoading } =
    useMutation(adminAPI.createExercise, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Exercise created successfully' });
        window.location.reload();
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const { mutate: updateExerciseMutation, isLoading: updateLoading } =
    useMutation(adminAPI.updateExerciseDetails, {
      onSuccess: () => {
        refetch();
        setOpenSideActionBoard();
        setAlarm({ type: 'success', message: 'Exercise updated successfully' });
        window.location.reload();
      },
      onError: (error) => {
        setOpenSideActionBoard();
        setAlarm({ type: 'error', message: error.message });
      },
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const exerciseData = {
      ...formData,
    };
    if (type === 'create') {
      createExerciseMutation({ behaviorId, exerciseData });
    } else if (type === 'edit') {
      updateExerciseMutation({ id, exerciseData });
    }
  };

  const isDetailView = type === 'view';

  const label = {
    view: 'View Exercise',
    edit: 'Edit Exercise',
    create: 'Create Exercise',
  };

  return (
    <>
      <div className="flex justify-between items-center relative pb-4 px-8">
        <button
          className="p-2 flex items-center -ml-2"
          type="button"
          onClick={() => setOpenSideActionBoard()}
        >
          <SVG src={ChevronRightIcon} />
        </button>
        <span
          style={{ width: 'calc(100% + 6rem)' }}
          className="absolute pin-b pin-r -mx-16 h-px bg-gray flex"
        />
      </div>

      <form
        className="overflow-y-scroll h-full px-8 py-8"
        onSubmit={handleSubmit}
      >
        <h1>{label[type]}</h1>

        <div style={{ gap: '16px' }} className="flex flex-col my-12">
          <div className="mb-6">
            <label className="font-semibold text-gray-700">Image</label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              disabled={isDetailView}
            />
          </div>

          <div className="mb-6">
            <label className="font-semibold text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
              rows="4"
              disabled={isDetailView}
            />
          </div>
        </div>

        {!isDetailView && (
          <button
            className="mb-20 p-4 bg-purple-500 text-white font-semibold rounded-lg hover:bg-purple-700 transition-colors"
            type="submit"
          >
            {type === 'create' ? 'Create Exercise' : 'Update Exercise'}
          </button>
        )}

        {(createLoading || updateLoading) && <FormLoader />}
      </form>
    </>
  );
};

export default ExerciseForm;
