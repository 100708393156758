import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { useSideBarContent } from '../../../context/SidebarContext';
import useResponsive from '../../../hooks/useResponsive';

// Default styles and classes
const itemClasses = 'flex items-center w-full p-3 border-2';
let itemStyles = {
  borderRadius: '32px',
  boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.1)',
};
let itemActiveStyles = {
  borderRadius: '32px',
  boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.1)',
};
const itemActive = 'bg-lightest-purple border-purple-500';
let itemNotActive = 'bg-white border-transparent';
const subElementClasses = 'ml-4 text-charcoal text font-semibold';
const subElementStyles = {};

const SidebarMenuItem = ({
  item,
  clickHandler,
  version,
  spanClasses,
  spanStyles,
}) => {
  const { icon, activeIcon, text, route } = item;
  const isActive = useMatch(route);

  if (version === '2') {
    itemStyles = { borderRadius: '12px', borderColor: 'white' };
    itemNotActive = 'bg-white';
    itemActiveStyles = { borderRadius: '12px', borderColor: '#f3f2fb' };
  }

  const classes = `${itemClasses} ${isActive ? itemActive : itemNotActive}`;
  return (
    <NavLink
      style={isActive ? itemActiveStyles : itemStyles}
      to={route}
      className={classes}
      onClick={() => clickHandler()}
    >
      <SVG src={isActive ? activeIcon : icon} />
      <span
        className={spanClasses || subElementClasses}
        style={spanStyles || subElementStyles}
      >
        {text}
      </span>
    </NavLink>
  );
};

const SidebarMenuItems = ({
  menuItems,
  divClasses,
  divStyles,
  spanClasses,
  spanStyles,
  version = '1',
  ...rest
}) => {
  const { setShowContentMobile } = useSideBarContent();
  const { isTablet } = useResponsive();

  let wrapperStyles = { gap: '24px' };
  let wrapperClasses = 'flex flex-col relative';

  if (divStyles) {
    wrapperStyles = divStyles;
  }

  if (divClasses) {
    wrapperClasses = divClasses;
  }

  const clickHandler = () => {
    if (isTablet) {
      setShowContentMobile(true);
    }
  };

  return (
    <>
      <div style={wrapperStyles} className={wrapperClasses} {...rest}>
        {menuItems.map((menuItem) => (
          <SidebarMenuItem
            item={menuItem}
            clickHandler={clickHandler}
            version={version}
            spanClasses={spanClasses}
            spanStyles={spanStyles}
          />
        ))}
      </div>
    </>
  );
};

export default SidebarMenuItems;
