import React, { useCallback, useState } from 'react';
import Button from '../../../../../components/Button';
import FormInput from '../../../../../components/FormInput';

const UserBulkForm = ({ handleSubmit }) => {
  const [formData, setFormData] = useState({
    file: undefined,
    filename: '',
    group: '',
  });
  const handleFormChange = useCallback(
    (key, value) => {
      setFormData((currentValue) => ({
        ...currentValue,
        [key]: value,
      }));
    },
    [formData]
  );

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      handleSubmit(formData);
    },
    [formData]
  );

  return (
    <div className="p-8">
      <h3
        className="pb-8 pt-12"
        style={{ fontSize: '30px', fontWeight: '800' }}
      >
        Add users in bulk
      </h3>

      <form onSubmit={handleFormSubmit}>
        <div className="pb-4" style={{ width: '300px' }}>
          <FormInput
            label="Upload CSV file"
            name="file"
            value={formData.filename}
            type="file"
            accept=".csv"
            handleChange={(e) => {
              handleFormChange('file', e.target.files[0]);
              handleFormChange('filename', e.target.value);
            }}
          />
        </div>

        <div className="pb-4" style={{ width: '300px' }}>
          <FormInput
            label="Group Name"
            name="group"
            value={formData.group}
            type="text"
            handleChange={(e) => handleFormChange('group', e.target.value)}
          />
        </div>

        <Button
          type="submit"
          variant="default-lowercase"
          className="font-bold capitalize"
          style={{ borderRadius: '12px' }}
        >
          Create Users
        </Button>
      </form>
    </div>
  );
};

export default UserBulkForm;
