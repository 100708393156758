import React, { useCallback } from 'react';
import { adminAPI } from '../../api';

const RemoveChat = ({ onClose, conversation }) => {
  const onDeleteConversation = useCallback(async () => {
    await adminAPI.deleteConversation(conversation.id);
    onClose(undefined, true);
  }, [conversation?.id]);

  return (
    <div className="chat-modal">
      <div className="chat-modal-body">
        <h3 className="text-2xl">Delete chat?</h3>
        <hgroup>
          <p>Are you sure you want to delete:</p>
          <p className="chat-name-ellipsis" title={conversation?.title}>
            {conversation?.title}
          </p>
        </hgroup>
        <div className="flex justify-end gap-4">
          <button type="button" className="chat-modal-button" onClick={onClose}>
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="chat-modal-button"
            onClick={onDeleteConversation}
          >
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveChat;
