import React from 'react';
import useModal from '../../../../context/ModalContext';

const FeedbackModal = ({ results }) => {
  const { closeModal } = useModal();

  return (
    <>
      {!results?.length ? (
        <p>Not identifier emails</p>
      ) : (
        <div>
          <h2 className=" font-semibold">Sent Invites - Results</h2>
          <div
            style={{ maxHeight: '800px' }}
            className="border-2 border-gray rounded-2lg p-4 overflow-y-scroll my-8"
          >
            {results.map((result) => (
              <>
                <div
                  key={result.email}
                  className={`flex items-center ${
                    result.success
                      ? 'p-2 text-green'
                      : 'pt-2 pr-2 pl-2 text-red'
                  } rounded-lg`}
                >
                  {result.success ? '✔' : '✘'}
                  <p className="pl-2">{result.email}</p>
                </div>
                {!result.success && (
                  <p className="text-sm text-red ml-6">error: {result.error}</p>
                )}
              </>
            ))}
          </div>

          <button
            onClick={closeModal}
            className=" font-semibold p-4 rounded-lg bg-blue-500 text-white mt-4"
          >
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default FeedbackModal;
